/// <reference types="./metadata.d.mts" />
import * as $dynamic from "../../gleam_stdlib/gleam/dynamic.mjs";
import * as $option from "../../gleam_stdlib/gleam/option.mjs";
import * as $implementations from "../data/implementations.mjs";
import { Implementations } from "../data/implementations.mjs";
import * as $decoder_extra from "../decoder_extra.mjs";
import { CustomType as $CustomType } from "../gleam.mjs";

export class Metadata extends $CustomType {
  constructor(deprecation, implementations) {
    super();
    this.deprecation = deprecation;
    this.implementations = implementations;
  }
}

export function decode_metadata(dyn) {
  return $dynamic.decode2(
    (var0, var1) => { return new Metadata(var0, var1); },
    $decoder_extra.completely_option("deprecation"),
    $dynamic.optional_field(
      "implementations",
      $dynamic.decode3(
        (var0, var1, var2) => { return new Implementations(var0, var1, var2); },
        $dynamic.field("gleam", $dynamic.bool),
        $dynamic.field("uses_erlang_externals", $dynamic.bool),
        $dynamic.field("uses_javascript_externals", $dynamic.bool),
      ),
    ),
  )(dyn);
}
