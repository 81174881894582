/// <reference types="./chart.d.mts" />
import * as $attribute from "../lustre/lustre/attribute.mjs";
import * as $element from "../lustre/lustre/element.mjs";
import { toList, CustomType as $CustomType } from "./gleam.mjs";

export class Dataset extends $CustomType {
  constructor(labels, data) {
    super();
    this.labels = labels;
    this.data = data;
  }
}

export function line_chart(datasets) {
  let datasets$1 = $attribute.property("datasets", datasets);
  return $element.element(
    "line-chart",
    toList([$attribute.style(toList([["display", "block"]])), datasets$1]),
    toList([]),
  );
}

export function bar_chart(color, datasets) {
  let datasets$1 = $attribute.property("datasets", datasets);
  let color$1 = $attribute.property("color", color);
  return $element.element(
    "bar-chart",
    toList([
      $attribute.style(toList([["display", "block"]])),
      datasets$1,
      color$1,
    ]),
    toList([]),
  );
}
