/// <reference types="./palette.d.mts" />
import { CustomType as $CustomType } from "../../gleam.mjs";

export class Palette extends $CustomType {
  constructor(white, dark_white, unnamed_blue, faff_pink, dark_faff_pink, aged_plastic_yellow, unexpected_aubergine, underwater_blue, charcoal, black, blacker) {
    super();
    this.white = white;
    this.dark_white = dark_white;
    this.unnamed_blue = unnamed_blue;
    this.faff_pink = faff_pink;
    this.dark_faff_pink = dark_faff_pink;
    this.aged_plastic_yellow = aged_plastic_yellow;
    this.unexpected_aubergine = unexpected_aubergine;
    this.underwater_blue = underwater_blue;
    this.charcoal = charcoal;
    this.black = black;
    this.blacker = blacker;
  }
}

export const dark = /* @__PURE__ */ new Palette(
  "#fefefc",
  "#aeaeac",
  "#a6f0fc",
  "#ffaff3",
  "#eb94de",
  "#fffbe8",
  "#584355",
  "#292d3e",
  "#2f2f2f",
  "#1e1e1e",
  "#151515",
);

export const erlang = "rgb(155, 32, 54)";

export const javascript = "#f7df1e";
