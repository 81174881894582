/// <reference types="./search_result.d.mts" />
import * as $dynamic from "../../gleam_stdlib/gleam/dynamic.mjs";
import * as $list from "../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../gleam_stdlib/gleam/option.mjs";
import { None, Some } from "../../gleam_stdlib/gleam/option.mjs";
import * as $result from "../../gleam_stdlib/gleam/result.mjs";
import * as $kind from "../data/kind.mjs";
import * as $metadata from "../data/metadata.mjs";
import * as $signature from "../data/signature.mjs";
import * as $helpers from "../frontend/view/helpers.mjs";
import { Ok, toList, CustomType as $CustomType } from "../gleam.mjs";

export class SearchResult extends $CustomType {
  constructor(documentation, module_name, name, kind, package_name, json_signature, metadata, version) {
    super();
    this.documentation = documentation;
    this.module_name = module_name;
    this.name = name;
    this.kind = kind;
    this.package_name = package_name;
    this.json_signature = json_signature;
    this.metadata = metadata;
    this.version = version;
  }
}

export class Start extends $CustomType {}

export class InternalServerError extends $CustomType {}

export class SearchResults extends $CustomType {
  constructor(exact_type_matches, exact_name_matches, name_signature_matches, vector_signature_searches, docs_searches, module_searches) {
    super();
    this.exact_type_matches = exact_type_matches;
    this.exact_name_matches = exact_name_matches;
    this.name_signature_matches = name_signature_matches;
    this.vector_signature_searches = vector_signature_searches;
    this.docs_searches = docs_searches;
    this.module_searches = module_searches;
  }
}

export function decode_search_result(dyn) {
  let _pipe = $dynamic.decode8(
    (var0, var1, var2, var3, var4, var5, var6, var7) => {
      return new SearchResult(var0, var1, var2, var3, var4, var5, var6, var7);
    },
    $dynamic.field("documentation", $dynamic.string),
    $dynamic.field("module_name", $dynamic.string),
    $dynamic.field("name", $dynamic.string),
    $dynamic.field("kind", $kind.decode_kind),
    $dynamic.field("package_name", $dynamic.string),
    $dynamic.field("json_signature", $signature.decode_signature),
    $dynamic.field("metadata", $metadata.decode_metadata),
    $dynamic.field("version", $dynamic.string),
  )(dyn);
  let _pipe$1 = $result.map(_pipe, (var0) => { return new Some(var0); });
  return $result.try_recover(_pipe$1, (_) => { return new Ok(new None()); });
}

export function decode_search_results_list(dyn) {
  return $result.map(
    $dynamic.list(decode_search_result)(dyn),
    (data) => {
      return $list.filter_map(
        data,
        (item) => { return $option.to_result(item, ""); },
      );
    },
  );
}

export function decode_search_results(dyn) {
  return $dynamic.any(
    toList([
      $dynamic.decode1(
        (_) => { return new InternalServerError(); },
        $dynamic.field("error", $dynamic.string),
      ),
      $dynamic.decode6(
        (var0, var1, var2, var3, var4, var5) => {
          return new SearchResults(var0, var1, var2, var3, var4, var5);
        },
        $dynamic.field("exact-type-matches", decode_search_results_list),
        $dynamic.field("exact-matches", decode_search_results_list),
        $dynamic.field("matches", decode_search_results_list),
        $dynamic.field("searches", decode_search_results_list),
        $dynamic.field("docs-searches", decode_search_results_list),
        $dynamic.field("module-searches", decode_search_results_list),
      ),
    ]),
  )(dyn);
}

export function hexdocs_link(search_result) {
  return $helpers.hexdocs_link(
    search_result.package_name,
    search_result.version,
    search_result.module_name,
    search_result.name,
  );
}
