/// <reference types="./config.d.mts" />
import * as $vitools from "../../vitools/vitools.mjs";

export function api_endpoint() {
  let $ = $vitools.is_dev();
  if ($) {
    return "http://localhost:3000";
  } else {
    return "https://api.gloogle.run";
  }
}
