/// <reference types="./strings.d.mts" />
export const gloogle_description = "Gloogle can search through all public gleam packages, to help you find the function you're looking for! Enter a type or a function name to get some results.";

export const types_match = "Matched directly from the signatures of functions.";

export const exact_match = "Matched directly from the name of functions, constants or types.";

export const partial_match = "Partly matched from the signature of functions, constants or types.";

export const searches_match = "Matched from a document search in functions, constants or types.";

export const docs_match = "Matched from a documentation search in functions, constants or types.";

export const modules_match = "Matched from a module name";

export const retry_query = "Retry with a different query. You can match functions, types or constants names, as well as functions types directly!";

export const internal_server_error = "Internal server error. The error should be fixed soon. Please, retry later.";

export const loading = "Your content is loading. Please wait…";

export const advanced_search = "Pro-tip: you can filter by name, signature or documentation! Type in:name, in:signature, in:documentation or any combination of all to select your filters. By default, it searches in names and signatures.";
