/// <reference types="./styles.d.mts" />
import * as $bool from "../../../../gleam_stdlib/gleam/bool.mjs";
import * as $s from "../../../../sketch/sketch.mjs";
import * as $l from "../../../../sketch/sketch/lustre/element.mjs";
import * as $media from "../../../../sketch/sketch/media.mjs";
import * as $size from "../../../../sketch/sketch/size.mjs";
import { px, vw } from "../../../../sketch/sketch/size.mjs";
import * as $palette from "../../../frontend/colors/palette.mjs";
import * as $body_styles from "../../../frontend/view/body/styles.mjs";
import { toList } from "../../../gleam.mjs";

export function search_input_wrapper(attributes, children) {
  return $l.memo("form", attributes, children, toList([$s.width_("100%")]));
}

export function navbar_search_title(attributes, children) {
  return $l.memo(
    "a",
    attributes,
    children,
    toList([
      $s.font_size($size.rem_(1.2)),
      $s.compose($body_styles.search_title_()),
      $s.text_decoration("none"),
      $s.cursor("pointer"),
    ]),
  );
}

export function nav_links(attributes, children) {
  return $l.memo(
    "div",
    attributes,
    children,
    toList([
      $s.display("flex"),
      $s.align_items("baseline"),
      $s.gap(px(48)),
      $s.padding(px(48)),
      $s.media(
        $media.max_width(px(700)),
        toList([$s.padding(px(12)), $s.gap(px(24))]),
      ),
    ]),
  );
}

export function coming_soon(attributes, children) {
  return $l.memo(
    "span",
    attributes,
    children,
    toList([$s.font_size($size.rem_(0.7))]),
  );
}

export function trending(attributes, children) {
  return $l.memo(
    "div",
    attributes,
    children,
    toList([
      $s.display("flex"),
      $s.flex_direction("column"),
      $s.gap(px(3)),
      $s.align_items("end"),
      $s.color("var(--text-color)"),
      $s.white_space("nowrap"),
      $s.opacity(0.3),
    ]),
  );
}

export function nav_link(attributes, children) {
  return $l.memo(
    "a",
    attributes,
    children,
    toList([$s.color("var(--text-color)"), $s.text_decoration("none")]),
  );
}

export function navbar(transparent, attributes, children) {
  let id = "navbar-transparent-" + $bool.to_string(transparent);
  return $l.dynamic(
    "nav",
    attributes,
    children,
    id,
    toList([
      $s.position("sticky"),
      $s.top(px(0)),
      $s.justify_content("end"),
      $s.grid_area("navbar"),
      $s.padding_left(px(48)),
      $s.gap(px(48)),
      $s.height(px(130)),
      $s.z_index(1000),
      $s.background(
        (() => {
          if (transparent) {
            return "transparent";
          } else {
            return "var(--sidebar-background)";
          }
        })(),
      ),
      $s.display(
        (() => {
          if (transparent) {
            return "flex";
          } else {
            return "none";
          }
        })(),
      ),
      $s.border_bottom(
        "1px solid " + (() => {
          if (transparent) {
            return "transparent";
          } else {
            return "var(--border-color)";
          }
        })(),
      ),
      $s.media(
        $media.max_width(px(700)),
        toList([
          $s.display("flex"),
          $s.gap(px(24)),
          $s.max_width(vw(100)),
          $s.height_("unset"),
          $s.padding_("18px 24px"),
        ]),
      ),
    ]),
  );
}

export function navbar_search(attributes, children) {
  return $l.memo(
    "div",
    attributes,
    children,
    toList([
      $s.display("flex"),
      $s.gap(px(48)),
      $s.align_items("center"),
      $s.flex("1"),
      $s.media($media.max_width(px(700)), toList([$s.gap(px(24))])),
    ]),
  );
}

export function title(a, c) {
  return $l.memo("div", a, c, toList([]));
}

export const search_lucy = $body_styles.search_lucy;
