/// <reference types="./package.d.mts" />
import * as $json from "../../gleam_json/gleam/json.mjs";
import * as $dynamic from "../../gleam_stdlib/gleam/dynamic.mjs";
import * as $option from "../../gleam_stdlib/gleam/option.mjs";
import * as $result from "../../gleam_stdlib/gleam/result.mjs";
import { toList, CustomType as $CustomType } from "../gleam.mjs";

export class Package extends $CustomType {
  constructor(name, repository, documentation, hex_url, licenses, description, rank, popularity) {
    super();
    this.name = name;
    this.repository = repository;
    this.documentation = documentation;
    this.hex_url = hex_url;
    this.licenses = licenses;
    this.description = description;
    this.rank = rank;
    this.popularity = popularity;
  }
}

export function decoder(dyn) {
  return $dynamic.decode8(
    (var0, var1, var2, var3, var4, var5, var6, var7) => {
      return new Package(var0, var1, var2, var3, var4, var5, var6, var7);
    },
    $dynamic.field("name", $dynamic.string),
    $dynamic.field("repository", $dynamic.optional($dynamic.string)),
    $dynamic.field("documentation", $dynamic.optional($dynamic.string)),
    $dynamic.field("hex-url", $dynamic.optional($dynamic.string)),
    $dynamic.field(
      "licenses",
      (dyn) => {
        return $result.try$(
          $dynamic.optional($dynamic.string)(dyn),
          (data) => {
            let _pipe = $option.unwrap(data, "[]");
            let _pipe$1 = $json.decode(_pipe, $dynamic.list($dynamic.string));
            return $result.replace_error(
              _pipe$1,
              toList([new $dynamic.DecodeError("", "", toList([]))]),
            );
          },
        );
      },
    ),
    $dynamic.field("description", $dynamic.optional($dynamic.string)),
    $dynamic.field("rank", $dynamic.optional($dynamic.int)),
    $dynamic.field(
      "popularity",
      (dyn) => {
        return $result.try$(
          $dynamic.optional($dynamic.string)(dyn),
          (data) => {
            let _pipe = $option.unwrap(data, "{}");
            let _pipe$1 = $json.decode(
              _pipe,
              $dynamic.field("github", $dynamic.int),
            );
            return $result.replace_error(
              _pipe$1,
              toList([new $dynamic.DecodeError("", "", toList([]))]),
            );
          },
        );
      },
    ),
  )(dyn);
}
