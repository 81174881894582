/// <reference types="./footer.d.mts" />
import * as $bool from "../../../../gleam_stdlib/gleam/bool.mjs";
import * as $list from "../../../../gleam_stdlib/gleam/list.mjs";
import * as $a from "../../../../lustre/lustre/attribute.mjs";
import * as $el from "../../../../lustre/lustre/element.mjs";
import * as $h from "../../../../lustre/lustre/element/html.mjs";
import * as $e from "../../../../lustre/lustre/event.mjs";
import * as $model from "../../../data/model.mjs";
import * as $msg from "../../../data/msg.mjs";
import * as $links from "../../../frontend/view/footer/links.mjs";
import { links } from "../../../frontend/view/footer/links.mjs";
import * as $s from "../../../frontend/view/footer/styles.mjs";
import * as $search_input from "../../../frontend/view/search_input/search_input.mjs";
import { toList } from "../../../gleam.mjs";

export function view() {
  return $s.footer(
    toList([]),
    toList([
      $s.footer_links(
        toList([]),
        $list.map(
          links,
          (_use0) => {
            let title = _use0[0];
            let links$1 = _use0[1];
            return $s.footer_section(
              toList([]),
              (() => {
                let _pipe = links$1;
                let _pipe$1 = $list.map(
                  _pipe,
                  (i) => {
                    return $s.foot_lk(
                      toList([$a.href(i[0])]),
                      toList([$h.text(i[1])]),
                    );
                  },
                );
                return $list.prepend(
                  _pipe$1,
                  $s.foot_title(toList([]), toList([$h.text(title)])),
                );
              })(),
            );
          },
        ),
      ),
      $s.footer_subtitles(
        toList([]),
        toList([
          $s.footer_built(
            toList([]),
            toList([
              $h.text(
                "Gloogle is proudly built with 💜 in Gleam for gleamlins",
              ),
            ]),
          ),
          $s.footer_built(
            toList([]),
            toList([
              $h.text("Gloogle is designed with 🔥 by "),
              $h.a(
                toList([
                  $a.href("https://gaspard.design/"),
                  $a.target("_blank"),
                  $a.rel("noreferrer"),
                ]),
                toList([$h.text("gaspard.design")]),
              ),
            ]),
          ),
        ]),
      ),
    ]),
  );
}

export function search_bar(model) {
  return $bool.guard(
    !model.is_mobile,
    $el.none(),
    () => {
      return $h.div(
        toList([$a.class$("footer-search")]),
        toList([
          $h.form(
            toList([$e.on_submit(new $msg.SubmitSearch())]),
            toList([$search_input.view(model.loading, model.input, true)]),
          ),
        ]),
      );
    },
  );
}
