/// <reference types="./view.d.mts" />
import * as $el from "../../lustre/lustre/element.mjs";
import * as $sketch from "../../sketch/sketch.mjs";
import * as $l from "../../sketch/sketch/lustre/element.mjs";
import * as $media from "../../sketch/sketch/media.mjs";
import * as $size from "../../sketch/sketch/size.mjs";
import { px } from "../../sketch/sketch/size.mjs";
import * as $model from "../data/model.mjs";
import * as $palette from "../frontend/colors/palette.mjs";
import * as $router from "../frontend/router.mjs";
import * as $body from "../frontend/view/body/body.mjs";
import * as $footer from "../frontend/view/footer/footer.mjs";
import * as $navbar from "../frontend/view/navbar/navbar.mjs";
import { toList } from "../gleam.mjs";

function layout(attributes, children) {
  return $l.memo(
    "div",
    attributes,
    children,
    toList([
      $sketch.display("grid"),
      $sketch.grid_template_areas(
        "\"sidebar navbar\"\n       \"sidebar main\"\n       \"sidebar footer\"",
      ),
      $sketch.property("--a-color", $palette.dark.faff_pink),
      $sketch.grid_template_columns("auto 1fr"),
      $sketch.grid_template_rows("auto 1fr auto"),
      $sketch.min_height($size.vh(100)),
      $sketch.media(
        $media.max_width(px(700)),
        toList([
          $sketch.grid_template_areas("\"navbar\" \"main\" \"footer\""),
          $sketch.grid_template_columns("1fr"),
        ]),
      ),
    ]),
  );
}

export function view(model) {
  return layout(
    toList([]),
    toList([
      $navbar.navbar(model),
      $body.body(model),
      (() => {
        let $ = model.route;
        if ($ instanceof $router.Home) {
          return $footer.view();
        } else if ($ instanceof $router.Search) {
          return $footer.search_bar(model);
        } else {
          return $el.none();
        }
      })(),
    ]),
  );
}
