/// <reference types="./styles.d.mts" />
import * as $s from "../../../../sketch/sketch.mjs";
import * as $l from "../../../../sketch/sketch/lustre/element.mjs";
import * as $size from "../../../../sketch/sketch/size.mjs";
import { px } from "../../../../sketch/sketch/size.mjs";
import * as $palette from "../../../frontend/colors/palette.mjs";
import { toList } from "../../../gleam.mjs";

export function footer(attributes, children) {
  return $l.memo(
    "footer",
    attributes,
    children,
    toList([
      $s.background("var(--sidebar-background)"),
      $s.display("flex"),
      $s.flex_direction("column"),
      $s.padding(px(24)),
      $s.align_items("center"),
      $s.gap(px(48)),
      $s.margin_top(px(48)),
      $s.grid_area("footer"),
    ]),
  );
}

export function footer_built(attributes, children) {
  return $l.memo(
    "div",
    attributes,
    children,
    toList([
      $s.align_items("center"),
      $s.justify_content("center"),
      $s.font_size($size.rem_(0.8)),
      $s.line_height("1.3"),
      $s.text_align("center"),
    ]),
  );
}

export function footer_subtitles(attributes, children) {
  return $l.memo(
    "div",
    attributes,
    children,
    toList([$s.display("flex"), $s.flex_direction("column"), $s.gap(px(4))]),
  );
}

export function footer_links(attributes, children) {
  return $l.memo(
    "div",
    attributes,
    children,
    toList([
      $s.display("grid"),
      $s.grid_template_columns("repeat(3, 1fr)"),
      $s.grid_template_rows("repeat(6, auto)"),
      $s.gap(px(12)),
      $s.max_width(px(700)),
      $s.width($size.percent(100)),
    ]),
  );
}

export function footer_section(attributes, children) {
  return $l.memo(
    "div",
    attributes,
    children,
    toList([
      $s.display("grid"),
      $s.grid_template_columns("1fr"),
      $s.grid_template_rows("subgrid"),
      $s.grid_row("1 / 7"),
    ]),
  );
}

export function foot_title(attributes, children) {
  return $l.memo(
    "div",
    attributes,
    children,
    toList([
      $s.color("var(--input-text-color)"),
      $s.font_weight("500"),
      $s.padding_("6px 0px"),
    ]),
  );
}

export function foot_lk(attributes, children) {
  return $l.memo(
    "a",
    attributes,
    children,
    toList([
      $s.font_size($size.rem_(0.9)),
      $s.color($palette.dark.dark_white),
      $s.text_decoration("none"),
    ]),
  );
}
