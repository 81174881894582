/// <reference types="./signature.d.mts" />
import * as $bool from "../../../../gleam_stdlib/gleam/bool.mjs";
import * as $int from "../../../../gleam_stdlib/gleam/int.mjs";
import * as $list from "../../../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../../../gleam_stdlib/gleam/option.mjs";
import { None, Some } from "../../../../gleam_stdlib/gleam/option.mjs";
import * as $string from "../../../../gleam_stdlib/gleam/string.mjs";
import * as $a from "../../../../lustre/lustre/attribute.mjs";
import * as $el from "../../../../lustre/lustre/element.mjs";
import * as $h from "../../../../lustre/lustre/element/html.mjs";
import * as $msg from "../../../data/msg.mjs";
import * as $search_result from "../../../data/search_result.mjs";
import * as $signature from "../../../data/signature.mjs";
import { Parameter } from "../../../data/signature.mjs";
import * as $helpers from "../../../frontend/view/helpers.mjs";
import * as $t from "../../../frontend/view/types.mjs";
import { toList, prepend as listPrepend, makeError } from "../../../gleam.mjs";

function do_render_parameters(from, to, acc) {
  return $bool.guard(
    from > to,
    acc,
    () => {
      let $ = (() => {
        let _pipe = $string.to_utf_codepoints("a");
        return $list.first(_pipe);
      })();
      if (!$.isOk()) {
        throw makeError(
          "assignment_no_match",
          "frontend/view/body/signature",
          17,
          "",
          "Assignment pattern did not match",
          { value: $ }
        )
      }
      let utf_a = $[0];
      let $1 = (() => {
        let _pipe = ($string.utf_codepoint_to_int(utf_a) + from);
        return $string.utf_codepoint(_pipe);
      })();
      if (!$1.isOk()) {
        throw makeError(
          "assignment_no_match",
          "frontend/view/body/signature",
          20,
          "",
          "Assignment pattern did not match",
          { value: $1 }
        )
      }
      let letter = $1[0];
      return do_render_parameters(
        from + 1,
        to,
        listPrepend(
          $t.variable($string.from_utf_codepoints(toList([letter]))),
          acc,
        ),
      );
    },
  );
}

function render_parameters(count) {
  if (count === 0) {
    return toList([]);
  } else {
    let count$1 = count;
    let _pipe = do_render_parameters(0, $int.max(count$1 - 1, 0), toList([]));
    let _pipe$1 = $list.reverse(_pipe);
    let _pipe$2 = $list.intersperse(_pipe$1, $h.text(", "));
    return ((t) => {
      return $list.concat(
        toList([toList([$h.text("(")]), t, toList([$h.text(")")])]),
      );
    })(_pipe$2);
  }
}

function view_type(type_, indent) {
  if (type_ instanceof $signature.Tuple) {
    let width = type_.width;
    let elements = type_.elements;
    let inline = (width + indent) <= 80;
    return $list.concat(
      toList([
        toList([$helpers.idt(indent), $h.text("#(")]),
        (() => {
          if (!inline) {
            return listPrepend(
              $helpers.newline(),
              (() => {
                let _pipe = $list.map(
                  elements,
                  (_capture) => { return view_type(_capture, indent + 2); },
                );
                let _pipe$1 = $list.intersperse(
                  _pipe,
                  toList([$h.text(","), $helpers.newline()]),
                );
                return $list.concat(_pipe$1);
              })(),
            );
          } else {
            let _pipe = $list.map(
              elements,
              (_capture) => { return view_type(_capture, 0); },
            );
            let _pipe$1 = $list.intersperse(_pipe, toList([$h.text(", ")]));
            return $list.concat(_pipe$1);
          }
        })(),
        toList([
          $bool.guard(
            inline,
            $h.text(""),
            () => { return $helpers.idt(indent); },
          ),
          $h.text(")"),
        ]),
      ]),
    );
  } else if (type_ instanceof $signature.Fn) {
    let width = type_.width;
    let parameters = type_.parameters;
    let return$ = type_.return;
    let inline = (width + indent) <= 80;
    return $list.concat(
      toList([
        toList([$helpers.idt(indent), $t.keyword("fn"), $h.text("(")]),
        (() => {
          if (inline) {
            let _pipe = $list.map(
              parameters,
              (_capture) => { return view_type(_capture, 0); },
            );
            let _pipe$1 = $list.intersperse(_pipe, toList([$h.text(", ")]));
            return $list.concat(_pipe$1);
          } else {
            return listPrepend(
              $helpers.newline(),
              (() => {
                let _pipe = $list.map(
                  parameters,
                  (_capture) => { return view_type(_capture, indent + 2); },
                );
                let _pipe$1 = $list.intersperse(
                  _pipe,
                  toList([$h.text(","), $helpers.newline()]),
                );
                return $list.concat(_pipe$1);
              })(),
            );
          }
        })(),
        $bool.guard(
          inline,
          toList([$h.text("")]),
          () => {
            return toList([
              $h.text(","),
              $helpers.newline(),
              $helpers.idt(indent),
            ]);
          },
        ),
        listPrepend(
          $h.text(")"),
          listPrepend(
            $h.text(" -> "),
            view_type(
              return$,
              (() => {
                if (!inline && (return$.width > 70)) {
                  return indent;
                } else {
                  return 0;
                }
              })(),
            ),
          ),
        ),
      ]),
    );
  } else if (type_ instanceof $signature.Variable) {
    let id = type_.id;
    return toList([
      $h.span(
        toList([]),
        (() => {
          let $ = (() => {
            let _pipe = $string.to_utf_codepoints("a");
            return $list.first(_pipe);
          })();
          if (!$.isOk()) {
            throw makeError(
              "assignment_no_match",
              "frontend/view/body/signature",
              100,
              "view_type",
              "Assignment pattern did not match",
              { value: $ }
            )
          }
          let utf_a = $[0];
          let $1 = (() => {
            let _pipe = ($string.utf_codepoint_to_int(utf_a) + id);
            return $string.utf_codepoint(_pipe);
          })();
          if (!$1.isOk()) {
            throw makeError(
              "assignment_no_match",
              "frontend/view/body/signature",
              103,
              "view_type",
              "Assignment pattern did not match",
              { value: $1 }
            )
          }
          let letter = $1[0];
          return toList([
            $helpers.idt(indent),
            $t.variable($string.from_utf_codepoints(toList([letter]))),
          ]);
        })(),
      ),
    ]);
  } else {
    let width = type_.width;
    let name = type_.name;
    let package$ = type_.package;
    let module = type_.module;
    let parameters = type_.parameters;
    let version = type_.ref;
    let inline = (width + indent) <= 80;
    let is_params = !$list.is_empty(parameters);
    return $list.concat(
      toList([
        toList([
          $helpers.idt(indent),
          (() => {
            if (version instanceof None) {
              return $t.type_(name);
            } else {
              let version$1 = version[0];
              return $h.a(
                toList([
                  $a.class$("named-type-button"),
                  $a.target("_blank"),
                  $a.rel("noreferrer"),
                  $a.href(
                    $helpers.hexdocs_link(package$, version$1, module, name),
                  ),
                ]),
                toList([$t.type_(name)]),
              );
            }
          })(),
          (() => {
            if (is_params) {
              return $h.text("(");
            } else {
              return $el.none();
            }
          })(),
        ]),
        (() => {
          if (inline) {
            let _pipe = $list.map(
              parameters,
              (_capture) => { return view_type(_capture, 0); },
            );
            let _pipe$1 = $list.intersperse(_pipe, toList([$h.text(", ")]));
            return $list.concat(_pipe$1);
          } else {
            return listPrepend(
              $helpers.newline(),
              (() => {
                let _pipe = $list.map(
                  parameters,
                  (_capture) => { return view_type(_capture, indent + 2); },
                );
                let _pipe$1 = $list.intersperse(
                  _pipe,
                  toList([$h.text(","), $helpers.newline()]),
                );
                return $list.concat(_pipe$1);
              })(),
            );
          }
        })(),
        toList([
          $bool.guard(
            inline,
            $h.text(""),
            () => { return $helpers.idt(indent); },
          ),
          (() => {
            if (is_params) {
              return $h.text(")");
            } else {
              return $el.none();
            }
          })(),
        ]),
      ]),
    );
  }
}

function view_parameter(parameter, indent) {
  let width = parameter.width;
  let label = parameter.label;
  let type_ = parameter.type_;
  return $list.concat(
    toList([
      (() => {
        if (label instanceof None) {
          return toList([$el.none()]);
        } else {
          let label$1 = label[0];
          return toList([$helpers.idt(indent), $t.label(label$1), $h.text(": ")]);
        }
      })(),
      (() => {
        let $ = width > 80;
        if (!$) {
          return view_type(type_, 0);
        } else if ($ && label instanceof Some) {
          return listPrepend($helpers.newline(), view_type(type_, indent + 2));
        } else {
          return view_type(type_, indent);
        }
      })(),
    ]),
  );
}

function view_type_constructor(constructor, indent) {
  let inline = constructor.params_width <= 70;
  let has_params = !$list.is_empty(constructor.parameters);
  return $list.concat(
    toList([
      toList([
        $helpers.idt(indent),
        $t.type_(constructor.name),
        (() => {
          if (has_params) {
            return $h.text("(");
          } else {
            return $el.none();
          }
        })(),
      ]),
      (() => {
        if (!inline) {
          return $list.concat(
            toList([
              toList([$helpers.newline()]),
              (() => {
                let _pipe = $list.map(
                  constructor.parameters,
                  (_capture) => {
                    return view_parameter(_capture, (indent + 2));
                  },
                );
                let _pipe$1 = $list.intersperse(
                  _pipe,
                  toList([$h.text(","), $helpers.newline()]),
                );
                return $list.concat(_pipe$1);
              })(),
              toList([$h.text(","), $helpers.newline()]),
            ]),
          );
        } else {
          let _pipe = $list.map(
            constructor.parameters,
            (_capture) => { return view_parameter(_capture, 0); },
          );
          let _pipe$1 = $list.intersperse(_pipe, toList([$h.text(", ")]));
          return $list.concat(_pipe$1);
        }
      })(),
      (() => {
        if (!has_params) {
          return toList([]);
        } else if (has_params && inline) {
          return toList([$h.text(")")]);
        } else {
          return toList([$helpers.idt(indent), $h.text(")")]);
        }
      })(),
    ]),
  );
}

export function view_signature(item) {
  let $ = item.json_signature;
  if ($ instanceof $signature.TypeDefinition) {
    let parameters = $.parameters;
    let constructors = $.constructors;
    return $list.concat(
      toList([
        listPrepend(
          $t.keyword("type "),
          listPrepend($t.fun(item.name), render_parameters(parameters)),
        ),
        (() => {
          if (constructors.hasLength(0)) {
            return toList([]);
          } else {
            return toList([$h.text(" {"), $helpers.newline()]);
          }
        })(),
        (() => {
          if (constructors.hasLength(0)) {
            return toList([]);
          } else {
            let _pipe = constructors;
            let _pipe$1 = $list.map(
              _pipe,
              (_capture) => { return view_type_constructor(_capture, 2); },
            );
            let _pipe$2 = $list.intersperse(
              _pipe$1,
              toList([$helpers.newline()]),
            );
            return $list.concat(_pipe$2);
          }
        })(),
        (() => {
          if (constructors.hasLength(0)) {
            return toList([]);
          } else {
            return toList([$helpers.newline(), $h.text("}")]);
          }
        })(),
      ]),
    );
  } else if ($ instanceof $signature.Constant) {
    let width = $.width;
    let type_ = $.type_;
    return $list.concat(
      toList([
        toList([$t.keyword("const "), $t.fun(item.name), $h.text(" = ")]),
        (() => {
          let $1 = width > 80;
          if ($1) {
            return listPrepend($helpers.newline(), view_type(type_, 2));
          } else {
            return view_type(type_, 0);
          }
        })(),
      ]),
    );
  } else if ($ instanceof $signature.TypeAlias) {
    let width = $.width;
    let parameters = $.parameters;
    let alias = $.alias;
    return $list.concat(
      toList([
        listPrepend(
          $t.keyword("type "),
          listPrepend($t.type_(item.name), render_parameters(parameters)),
        ),
        toList([$h.text(" = ")]),
        (() => {
          let $1 = width > 80;
          if ($1) {
            return listPrepend($helpers.newline(), view_type(alias, 2));
          } else {
            return view_type(alias, 0);
          }
        })(),
      ]),
    );
  } else {
    let params_width = $.params_width;
    let name = $.name;
    let return$ = $.return;
    let parameters = $.parameters;
    return $list.concat(
      toList([
        toList([$t.keyword("fn "), $t.fun(name), $h.text("(")]),
        (() => {
          let $1 = params_width > 70;
          if ($1) {
            return $list.concat(
              toList([
                toList([$helpers.newline(), $helpers.idt(2)]),
                (() => {
                  let _pipe = $list.map(
                    parameters,
                    (_capture) => { return view_parameter(_capture, 2); },
                  );
                  let _pipe$1 = $list.intersperse(
                    _pipe,
                    toList([$h.text(","), $helpers.newline(), $helpers.idt(2)]),
                  );
                  return $list.concat(_pipe$1);
                })(),
                toList([$h.text(","), $helpers.newline()]),
              ]),
            );
          } else {
            let _pipe = $list.map(
              parameters,
              (_capture) => { return view_parameter(_capture, 0); },
            );
            let _pipe$1 = $list.intersperse(_pipe, toList([$h.text(", ")]));
            return $list.concat(_pipe$1);
          }
        })(),
        listPrepend(
          $h.text(") -> "),
          (() => {
            let $1 = (return$.width + $string.length(name)) > 60;
            if ($1) {
              return listPrepend($helpers.newline(), view_type(return$, 2));
            } else {
              return view_type(return$, 0);
            }
          })(),
        ),
      ]),
    );
  }
}
