/// <reference types="./error.d.mts" />
import * as $json from "../../gleam_json/gleam/json.mjs";
import * as $dynamic from "../../gleam_stdlib/gleam/dynamic.mjs";
import * as $int from "../../gleam_stdlib/gleam/int.mjs";
import * as $list from "../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../gleam_stdlib/gleam/option.mjs";
import { Some } from "../../gleam_stdlib/gleam/option.mjs";
import * as $string from "../../gleam_stdlib/gleam/string.mjs";
import * as $http from "../../lustre_http/lustre_http.mjs";

export function describe_json_error(error) {
  let _pipe = (() => {
    if (error instanceof $json.UnexpectedEndOfInput) {
      return "Impossible to parse the response. Unexpected end of input.";
    } else if (error instanceof $json.UnexpectedByte) {
      let byte = error.byte;
      let position = error.position;
      return "Impossible to parse the response. Unexpected byte. " + ((byte + " at position ") + $int.to_string(
        position,
      ));
    } else if (error instanceof $json.UnexpectedSequence) {
      let byte = error.byte;
      let position = error.position;
      return "Impossible to parse the response. Unexpected sequence. " + ((byte + " at position ") + $int.to_string(
        position,
      ));
    } else {
      let errors = error[0];
      return $string.append(
        "Impossible to parse the response. Unexpected format. ",
        (() => {
          let _pipe = errors;
          let _pipe$1 = $list.map(
            _pipe,
            (error) => {
              let expected = error.expected;
              let found = error.found;
              let path = error.path;
              let expected$1 = "Expected value: " + expected;
              let found$1 = "Found: " + found;
              let path$1 = "Path: " + $string.join(path, ".");
              return ((((expected$1 + ". ") + found$1) + ". ") + path$1) + ".";
            },
          );
          return $string.join(_pipe$1, "\n");
        })(),
      );
    }
  })();
  return new Some(_pipe);
}

export function describe_http_error(error) {
  if (error instanceof $http.BadUrl) {
    let url = error[0];
    return new Some("Bad URL: " + url);
  } else if (error instanceof $http.InternalServerError) {
    let error$1 = error[0];
    return new Some(
      ("Internal server error. Please try again later. " + error$1),
    );
  } else if (error instanceof $http.JsonError) {
    let error$1 = error[0];
    return describe_json_error(error$1);
  } else if (error instanceof $http.NetworkError) {
    return new Some("Network error. Please try again later.");
  } else if (error instanceof $http.NotFound) {
    return new Some("Resource not found. Please try again later.");
  } else if (error instanceof $http.OtherError) {
    let error_code = error[0];
    let description = error[1];
    return new Some(
      (("Error " + $int.to_string(error_code)) + ". ") + description,
    );
  } else {
    return new Some("Operation unauthorized.");
  }
}
