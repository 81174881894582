/// <reference types="./cache.d.mts" />
import * as $list from "../../../../gleam_stdlib/gleam/list.mjs";
import * as $a from "../../../../lustre/lustre/attribute.mjs";
import * as $el from "../../../../lustre/lustre/element.mjs";
import * as $h from "../../../../lustre/lustre/element/html.mjs";
import * as $e from "../../../../lustre/lustre/event.mjs";
import * as $msg from "../../../data/msg.mjs";
import * as $search_result from "../../../data/search_result.mjs";
import * as $sr from "../../../frontend/view/body/search_result.mjs";
import * as $t from "../../../frontend/view/types.mjs";
import { toList, prepend as listPrepend } from "../../../gleam.mjs";

function view_search_results(search_results) {
  let _pipe = $list.map(search_results, $sr.view);
  let _pipe$1 = $list.intersperse(
    _pipe,
    $h.div(toList([$a.class$("search-result-separator")]), toList([])),
  );
  return $el.fragment(_pipe$1);
}

function sidebar(search, index) {
  return $h.div(
    toList([$a.class$("sidebar-wrapper")]),
    listPrepend(
      $h.div(
        toList([$a.class$("sidebar-wrapper-title")]),
        toList([$el.text(("Packages for “" + search) + "”")]),
      ),
      $list.map(
        index,
        (_use0) => {
          let package$ = _use0[0];
          let modules = _use0[1];
          return $h.div(
            toList([$a.class$("sidebar-package-wrapper")]),
            listPrepend(
              $h.div(
                toList([$a.class$("sidebar-package-name")]),
                toList([$h.text(package$[0]), $t.dark_white("@" + package$[1])]),
              ),
              $list.map(
                modules,
                (module) => {
                  let module$1 = module[0];
                  let name = module[1];
                  let id = (((((package$[0] + "@") + package$[1]) + "-") + module$1) + "-") + name;
                  return $h.div(
                    toList([
                      $a.class$("sidebar-module-name"),
                      $e.on_click(new $msg.ScrollTo(id)),
                    ]),
                    toList([$t.keyword(module$1), $h.text("."), $t.fun(name)]),
                  );
                },
              ),
            ),
          );
        },
      ),
    ),
  );
}

function maybe_separator(l) {
  let $ = $list.is_empty(l);
  if ($) {
    return $el.none();
  } else {
    return $h.div(toList([$a.class$("search-result-separator")]), toList([]));
  }
}

function empty_types() {
  return $h.div(
    toList([$a.class$("search-result-empty-callout")]),
    toList([
      $h.text("Unfortunately, your query did not find any matching function. "),
      $h.text("Keep in mind you can use _ when you don't "),
      $h.text("know the type you're searching for."),
      $h.br(toList([])),
      $h.text("You can still take a look below, in case the vector search"),
      $h.text(
        " returned an approximate result. Otherwise, refine your request!",
      ),
    ]),
  );
}

function types_separator() {
  return $h.div(
    toList([$a.class$("search-result-empty-callout")]),
    toList([
      $h.text(
        "Starting here, Gloogle tries to respond to your query by trying ",
      ),
      $h.text("different strategies, like looking in documentation or with "),
      $h.text("a vector search!"),
      $h.br(toList([])),
      $h.text(
        "Results can be approximate, but maybe you'll find what you need!",
      ),
    ]),
  );
}

export function cache_search_results(
  search,
  index,
  types,
  exact,
  others,
  searches,
  docs_searches,
  modules_searches
) {
  return $h.div(
    toList([$a.class$("search-results-wrapper")]),
    toList([
      sidebar(search, index),
      $h.div(
        toList([$a.class$("items-wrapper")]),
        toList([
          $h.div(
            toList([$a.class$("matches-titles")]),
            toList([
              $h.div(
                toList([$a.class$("matches-title")]),
                toList([$h.text(("Search results for “" + search) + "”")]),
              ),
            ]),
          ),
          (() => {
            if (types.hasLength(0)) {
              return empty_types();
            } else {
              let types$1 = types;
              return $el.fragment(
                toList([
                  view_search_results(types$1),
                  $h.div(
                    toList([$a.class$("search-result-separator")]),
                    toList([]),
                  ),
                  types_separator(),
                ]),
              );
            }
          })(),
          $h.div(toList([$a.class$("search-result-separator")]), toList([])),
          view_search_results(exact),
          maybe_separator(exact),
          view_search_results(others),
          maybe_separator(others),
          view_search_results(searches),
          maybe_separator(searches),
          view_search_results(docs_searches),
          maybe_separator(docs_searches),
          view_search_results(modules_searches),
        ]),
      ),
    ]),
  );
}
