/// <reference types="./styles.d.mts" />
import * as $bool from "../../../../gleam_stdlib/gleam/bool.mjs";
import * as $int from "../../../../gleam_stdlib/gleam/int.mjs";
import * as $list from "../../../../gleam_stdlib/gleam/list.mjs";
import * as $a from "../../../../lustre/lustre/attribute.mjs";
import * as $element from "../../../../lustre/lustre/element.mjs";
import * as $s from "../../../../sketch/sketch.mjs";
import * as $el from "../../../../sketch/sketch/lustre/element.mjs";
import * as $media from "../../../../sketch/sketch/media.mjs";
import * as $size from "../../../../sketch/sketch/size.mjs";
import { percent, px, vh, vw } from "../../../../sketch/sketch/size.mjs";
import * as $palette from "../../../frontend/colors/palette.mjs";
import { toList, prepend as listPrepend } from "../../../gleam.mjs";

export function implementations_pill_container(attrs, children) {
  let id = "implementations_pill_container";
  return $el.memo_dynamic(
    "div",
    attrs,
    children,
    id,
    toList([
      $s.display("flex"),
      $s.align_items("center"),
      $s.font_size(px(14)),
      $s.font_weight("300"),
      $s.gap(px(6)),
    ]),
  );
}

export function implementations_pill(background, color, attributes, children) {
  let id = "implementations-pill-" + background;
  let _pipe = $s.dynamic(
    id,
    toList([
      $s.background(background),
      $s.border_radius(px(6)),
      $s.width(px(8)),
      $s.height(px(8)),
    ]),
  );
  let _pipe$1 = $s.memo(_pipe);
  let _pipe$2 = $s.to_lustre(_pipe$1);
  let _pipe$3 = ((_capture) => { return $list.prepend(attributes, _capture); })(
    _pipe$2,
  );
  return ((_capture) => { return $element.element("div", _capture, children); })(
    _pipe$3,
  );
}

export function implementations_pill_wrapper(attributes, children) {
  let id = "implementations_pill_wrapper";
  return $el.memo_dynamic(
    "div",
    attributes,
    children,
    id,
    toList([
      $s.display("flex"),
      $s.align_items("center"),
      $s.gap(px(12)),
      $s.justify_content("end"),
    ]),
  );
}

export function search_result(attributes, children) {
  let id = "search_result";
  return $el.memo_dynamic(
    "div",
    attributes,
    children,
    id,
    toList([
      $s.overflow("hidden"),
      $s.display("flex"),
      $s.flex_direction("column"),
      $s.gap(px(12)),
    ]),
  );
}

export function search_results_wrapper(attributes, children) {
  let id = "search_results_wrapper";
  return $el.memo_dynamic(
    "div",
    attributes,
    children,
    id,
    toList([
      $s.display("grid"),
      $s.padding_right(px(48)),
      $s.gap(px(36)),
      $s.grid_template_columns("min-content 1fr"),
      $s.justify_items("center"),
      $s.media(
        $media.max_width(px(700)),
        toList([$s.grid_template_columns("1fr"), $s.padding_("0 24px")]),
      ),
    ]),
  );
}

export function external_icon_wrapper(attrs, children) {
  let id = "external_icon_wrapper";
  return $el.memo_dynamic(
    "div",
    attrs,
    children,
    id,
    toList([$s.width(px(16)), $s.height(px(16))]),
  );
}

export function search_details(attributes, children) {
  let id = "search_details";
  return $el.memo_dynamic(
    "div",
    attributes,
    children,
    id,
    toList([
      $s.display("flex"),
      $s.gap(px(12)),
      $s.align_items("center"),
      $s.media($media.max_width(px(700)), toList([$s.flex_direction("column")])),
    ]),
  );
}

export function search_details_title(attributes, children) {
  let id = "search_details_title";
  return $el.memo_dynamic(
    "div",
    attributes,
    children,
    id,
    toList([$s.display("flex"), $s.align_items("center"), $s.gap(px(12))]),
  );
}

export function qualified_name(attributes, children) {
  let id = "qualified_name";
  return $el.memo_dynamic(
    "a",
    attributes,
    children,
    id,
    toList([
      $s.overflow("hidden"),
      $s.text_overflow("ellipsis"),
      $s.direction("rtl"),
      $s.text_decoration("none"),
      $s.hover(toList([$s.text_decoration("underline")])),
    ]),
  );
}

export function search_body(attributes, children) {
  let id = "search_body";
  return $el.memo_dynamic(
    "div",
    attributes,
    children,
    id,
    toList([
      $s.background("rgba(254, 254, 252, 0.05)"),
      $s.border_radius(px(12)),
      $s.padding_("12px 24px"),
      $s.border("1px solid rgba(254, 254, 252, .1)"),
    ]),
  );
}

export function signature(attributes, children) {
  let id = "signature";
  return $el.memo_dynamic(
    "code",
    attributes,
    children,
    id,
    toList([
      $s.white_space("pre-wrap"),
      $s.display("block"),
      $s.line_height("1.6"),
      $s.overflow("auto"),
    ]),
  );
}

export function documentation(attributes, children) {
  let id = "documentation";
  return $el.memo_dynamic(
    "div",
    attributes,
    children,
    id,
    toList([
      $s.display("flex"),
      $s.flex_direction("column"),
      $s.gap(px(12)),
      $s.padding_top(px(6)),
    ]),
  );
}

export function search_result_separator() {
  let id = "search_result_separator";
  return $el.memo_dynamic(
    "div",
    toList([]),
    toList([]),
    id,
    toList([
      $s.height(px(1)),
      $s.background("rgba(254, 254, 252, 0.1)"),
      $s.margin_("6px 0"),
    ]),
  );
}

export function documentation_title(attributes, children) {
  let id = "documentation_title";
  return $el.memo_dynamic(
    "div",
    attributes,
    children,
    id,
    toList([$s.color($palette.dark.dark_white)]),
  );
}

export function search_wrapper(attributes, children) {
  let id = "search_wrapper";
  return $el.memo_dynamic(
    "form",
    attributes,
    children,
    id,
    toList([
      $s.display("grid"),
      $s.grid_template_rows("auto auto auto"),
      $s.grid_template_columns("auto auto auto"),
      $s.grid_template_areas(
        "\"title .     .\"\n       \"input input input\"\n       \".     .     submit\"",
      ),
      $s.padding(px(48)),
      $s.gap(px(24)),
      $s.max_width(px(700)),
      $s.width($size.percent(100)),
      $s.margin_("auto"),
      $s.media(
        $media.max_width(px(700)),
        toList([
          $s.max_width(vw(100)),
          $s.padding(px(24)),
          $s.grid_template_areas(
            "\"title title title\"\n         \"input input input\"\n         \".     .     submit\"",
          ),
        ]),
      ),
    ]),
  );
}

export function search_title_() {
  return $s.class$(
    toList([
      $s.font_family("Lexend"),
      $s.display("flex"),
      $s.align_items("center"),
      $s.gap(px(12)),
      $s.color("var(--text-color)"),
    ]),
  );
}

export function search_title(attributes, children) {
  let id = "search_title";
  return $el.memo_dynamic(
    "div",
    attributes,
    children,
    id,
    toList([$s.compose(search_title_()), $s.font_size($size.rem_(2.5))]),
  );
}

export function search_title_wrapper(attributes, children) {
  let id = "search_title_wrapper";
  return $el.memo_dynamic(
    "div",
    attributes,
    children,
    id,
    toList([
      $s.grid_area("title"),
      $s.display("flex"),
      $s.flex_direction("column"),
      $s.gap(px(9)),
      $s.font_size($size.rem_(0.9)),
      $s.color($palette.dark.dark_white),
      $s.line_height("1.3"),
    ]),
  );
}

export function search_lucy(size, attributes) {
  let id = "search-lucy-" + $int.to_string(size);
  let _pipe = $s.dynamic(id, toList([$s.width(px(size))]));
  let _pipe$1 = $s.memo(_pipe);
  let _pipe$2 = $s.to_lustre(_pipe$1);
  let _pipe$3 = ((_capture) => { return $list.prepend(attributes, _capture); })(
    _pipe$2,
  );
  return ((_capture) => { return $element.element("img", _capture, toList([])); })(
    _pipe$3,
  );
}

export function search_submit(attributes) {
  let id = "search_submit";
  return $el.memo_dynamic(
    "input",
    attributes,
    toList([]),
    id,
    toList([
      $s.grid_area("submit"),
      $s.appearance("none"),
      $s.border("none"),
      $s.background($palette.dark.faff_pink),
      $s.border_radius(px(50)),
      $s.padding_top(px(12)),
      $s.padding_bottom(px(12)),
      $s.padding_right(px(24)),
      $s.padding_left(px(24)),
      $s.color($palette.dark.charcoal),
      $s.font_size($size.rem(1)),
      $s.outline("none"),
      $s.transition("background .3s"),
      $s.active(toList([$s.background($palette.dark.dark_faff_pink)])),
      $s.focus(toList([$s.background($palette.dark.dark_faff_pink)])),
      $s.disabled(toList([$s.background($palette.dark.unexpected_aubergine)])),
    ]),
  );
}

export function matches_titles(attributes, children) {
  let id = "matches_titles";
  return $el.memo_dynamic(
    "div",
    attributes,
    children,
    id,
    toList([
      $s.line_height("1.3"),
      $s.color($palette.dark.dark_white),
      $s.display("flex"),
      $s.align_items("baseline"),
      $s.gap(px(6)),
      $s.font_size(px(12)),
      $s.media($media.max_width(px(700)), toList([$s.flex_direction("column")])),
    ]),
  );
}

export function matches_title(attributes, children) {
  let id = "matches_title";
  return $el.memo_dynamic(
    "div",
    attributes,
    children,
    id,
    toList([$s.color($palette.dark.white), $s.font_size(px(18))]),
  );
}

export function empty_state(attributes, children) {
  let id = "empty_state";
  return $el.memo_dynamic(
    "div",
    attributes,
    children,
    id,
    toList([
      $s.grid_row("span 3"),
      $s.display("flex"),
      $s.align_items("center"),
      $s.gap(px(24)),
      $s.justify_content("center"),
      $s.max_width(px(700)),
      $s.width($size.percent(100)),
      $s.margin_("auto"),
      $s.padding(px(24)),
    ]),
  );
}

export function empty_state_lucy(attributes) {
  let id = "empty_state_lucy";
  return $el.memo_dynamic(
    "img",
    attributes,
    toList([]),
    id,
    toList([$s.width(px(100))]),
  );
}

export function empty_state_titles(attributes, children) {
  let id = "empty_state_titles";
  return $el.memo_dynamic(
    "div",
    attributes,
    children,
    id,
    toList([
      $s.font_size(px(20)),
      $s.display("flex"),
      $s.flex_direction("column"),
      $s.gap(px(9)),
      $s.line_height("1.3"),
      $s.max_width(px(400)),
    ]),
  );
}

export function empty_state_subtitle(attributes, children) {
  let id = "empty_state_subtitle";
  return $el.memo_dynamic(
    "div",
    attributes,
    children,
    id,
    toList([$s.font_size(px(16)), $s.color($palette.dark.dark_white)]),
  );
}

export function sidebar_wrapper(attributes, children) {
  let id = "sidebar_wrapper";
  return $el.memo_dynamic(
    "div",
    attributes,
    children,
    id,
    toList([
      $s.position("sticky"),
      $s.top(px(0)),
      $s.display("flex"),
      $s.flex_direction("column"),
      $s.gap(px(12)),
      $s.height(vh(100)),
      $s.overflow("auto"),
      $s.padding_("12px"),
      $s.border_right("1px solid rgba(254, 254, 252, .1)"),
      $s.property(
        "mask",
        "linear-gradient(\n        180deg,\n        rgba(255,255,255, 0) 0%,\n        rgba(255,255,255,1) 0.5% 99.5%,\n        rgba(255, 255, 255, 0)\n      )",
      ),
      $s.media($media.max_width(px(700)), toList([$s.display("none")])),
    ]),
  );
}

export function sidebar_wrapper_title(attrs, children) {
  let id = "sidebar_wrapper_title";
  return $el.memo_dynamic(
    "div",
    attrs,
    children,
    id,
    toList([$s.padding_("4px 4px"), $s.color("#ffffff99")]),
  );
}

export function sidebar_package_name(attributes, children) {
  let id = "sidebar_package_name";
  return $el.memo_dynamic(
    "div",
    attributes,
    children,
    id,
    toList([
      $s.padding_left(px(8)),
      $s.border_radius(px(6)),
      $s.overflow("hidden"),
      $s.text_overflow("ellipsis"),
    ]),
  );
}

export function sidebar_module_name(attributes, children) {
  let id = "sidebar_module_name";
  return $el.memo_dynamic(
    "button",
    attributes,
    children,
    id,
    toList([
      $s.text_overflow("ellipsis"),
      $s.overflow("hidden"),
      $s.direction("rtl"),
      $s.text_align("left"),
      $s.cursor("pointer"),
      $s.hover(toList([$s.text_decoration("underline")])),
      $s.appearance("none"),
      $s.background("inherit"),
      $s.border("none"),
      $s.color("inherit"),
      $s.font_size_("inherit"),
      $s.line_height("inherit"),
      $s.display("block"),
      $s.property("padding-block", "0"),
      $s.property("padding-inline", "0"),
      $s.padding_left(px(16)),
    ]),
  );
}

export function sidebar_package_wrapper(attributes, children) {
  let id = "sidebar_package_wrapper";
  return $el.memo_dynamic(
    "div",
    attributes,
    children,
    id,
    toList([
      $s.display("flex"),
      $s.flex_direction("column"),
      $s.gap(px(9)),
      $s.font_size(px(14)),
      $s.max_width(px(250)),
    ]),
  );
}

export function main(attributes, children) {
  let id = "main";
  return $el.memo_dynamic(
    "main",
    attributes,
    children,
    id,
    toList([
      $s.grid_area("main"),
      $s.display("flex"),
      $s.flex_direction("column"),
      $s.gap(px(24)),
    ]),
  );
}

export function sidebar_title(attrs, children) {
  let id = "sidebar_title";
  return $el.memo_dynamic(
    "a",
    attrs,
    children,
    id,
    toList([
      $s.display("flex"),
      $s.align_items("center"),
      $s.gap(px(16)),
      $s.color("inherit"),
      $s.text_decoration("none"),
    ]),
  );
}

export function sidebar_title_inside(attrs, children) {
  let id = "sidebar_title_inside";
  return $el.memo_dynamic("div", attrs, children, id, toList([]));
}

export function form_wrapper(attrs, children) {
  let id = "form_wrapper";
  return $el.memo_dynamic("form", attrs, children, id, toList([]));
}

export function sidebar_filter(attrs, children) {
  let id = "sidebar_filter";
  return $el.memo_dynamic(
    "div",
    attrs,
    children,
    id,
    toList([
      $s.padding_top(px(12)),
      $s.padding_left(px(12)),
      $s.color("rgba(254, 254, 252, .6)"),
    ]),
  );
}

export function sidebar_filters(attrs, children) {
  let id = "sidebar_filters";
  return $el.memo_dynamic(
    "div",
    attrs,
    children,
    id,
    toList([
      $s.display("flex"),
      $s.flex_direction("column"),
      $s.gap(px(18)),
      $s.padding_("0 12px"),
    ]),
  );
}

export function sidebar_checkbox(active, attrs) {
  let val = $bool.to_string(active);
  let id1 = "sidebar-checkbox-div-" + val;
  let id2 = "sidebar-checkbox-input-" + val;
  return $element.fragment(
    toList([
      $el.memo_dynamic(
        "div",
        toList([]),
        toList([]),
        id1,
        toList([
          $s.width(px(16)),
          $s.height(px(16)),
          $s.background(
            (() => {
              if (active) {
                return "#ffaff3";
              } else {
                return "rgba(254, 254, 252, .1)";
              }
            })(),
          ),
          $s.border("1px solid rgba(254, 254, 252, .1)"),
          $s.border_radius(px(4)),
        ]),
      ),
      $el.memo_dynamic(
        "input",
        listPrepend(
          $a.type_("checkbox"),
          listPrepend($a.checked(active), attrs),
        ),
        toList([]),
        id2,
        toList([
          $s.position("fixed"),
          $s.top(px(-1000)),
          $s.width(px(1)),
          $s.height(px(1)),
        ]),
      ),
    ]),
  );
}

export function sidebar_spacer(attrs, children) {
  let id = "sidebar_spacer";
  return $el.memo_dynamic("div", attrs, children, id, toList([$s.flex("1")]));
}

export function filter_separator(attrs, children) {
  let id = "filter_separator";
  return $el.memo_dynamic(
    "div",
    attrs,
    children,
    id,
    toList([$s.height(px(1)), $s.background("rgba(254, 254, 252, .1)")]),
  );
}

export function sidebar_filter_line(attrs, children) {
  let id = "sidebar_filter_line";
  return $el.memo_dynamic(
    "label",
    attrs,
    children,
    id,
    toList([
      $s.display("flex"),
      $s.gap(px(9)),
      $s.cursor("pointer"),
      $s.align_items("center"),
    ]),
  );
}

export function sidebar_filter_name(attrs, children) {
  let id = "sidebar_filter_name";
  return $el.memo_dynamic(
    "div",
    attrs,
    children,
    id,
    toList([$s.color("white")]),
  );
}

export function sidebar_links(attrs, children) {
  let id = "sidebar_links";
  return $el.memo_dynamic(
    "div",
    attrs,
    children,
    id,
    toList([
      $s.display("flex"),
      $s.flex_direction("column"),
      $s.gap(px(24)),
      $s.padding(px(12)),
    ]),
  );
}

export function sidebar_link_wrapper(attrs, children) {
  let id = "sidebar_link_wrapper";
  return $el.memo_dynamic(
    "a",
    attrs,
    children,
    id,
    toList([
      $s.display("flex"),
      $s.align_items("baseline"),
      $s.gap(px(9)),
      $s.text_decoration("none"),
      $s.color("inherit"),
    ]),
  );
}

export function sidebar_icon(attrs, children) {
  let id = "sidebar_icon";
  return $el.memo_dynamic(
    "div",
    attrs,
    children,
    id,
    toList([$s.width(px(12)), $s.height(px(12))]),
  );
}

export function sidebar_link(attrs, children) {
  let id = "sidebar_link";
  return $el.memo_dynamic(
    "div",
    attrs,
    children,
    id,
    toList([$s.font_size(px(14)), $s.color("rgba(254, 254, 252, 0.6)")]),
  );
}

export function items_wrapper(attributes, children) {
  let id = "items_wrapper";
  return $el.memo_dynamic(
    "div",
    attributes,
    children,
    id,
    toList([
      $s.display("flex"),
      $s.flex_direction("column"),
      $s.gap(px(24)),
      $s.padding_top(px(24)),
      $s.max_width(px(700)),
      $s.width($size.percent(100)),
      $s.overflow("hidden"),
    ]),
  );
}

export function named_type_button(attributes, children) {
  let id = "named_type_button";
  return $el.memo_dynamic(
    "a",
    attributes,
    children,
    id,
    toList([$s.color("#e5c07b")]),
  );
}

export function search_title_with_hint(attributes, children) {
  let id = "search_title_with_hint";
  return $el.memo_dynamic(
    "div",
    attributes,
    children,
    id,
    toList([$s.display("flex"), $s.gap(px(12))]),
  );
}

export function pre_alpha_title(attributes, children) {
  let id = "pre_alpha_title";
  return $el.memo_dynamic(
    "div",
    attributes,
    children,
    id,
    toList([$s.font_size(px(16))]),
  );
}

export function loading_trending(attributes, children) {
  let id = "loading_trending";
  return $el.memo_dynamic("div", attributes, children, id, toList([]));
}

export function no_trendings(attributes, children) {
  let id = "no_trendings";
  return $el.memo_dynamic("div", attributes, children, id, toList([]));
}

export function trendings_wrapper(attributes, children) {
  let id = "trendings_wrapper";
  return $el.memo_dynamic(
    "div",
    attributes,
    children,
    id,
    toList([$s.padding_("12px 48px")]),
  );
}

export function trendings_title(attributes, children) {
  let id = "trendings_title";
  return $el.memo_dynamic(
    "div",
    attributes,
    children,
    id,
    toList([$s.margin_bottom(px(24))]),
  );
}

export function trendings_grid(attributes, children) {
  let id = "trendings_grid";
  return $el.memo_dynamic(
    "div",
    attributes,
    children,
    id,
    toList([$s.display("grid"), $s.align_items("start"), $s.gap(px(24))]),
  );
}

export function trendings_card(attributes, children) {
  let id = "trendings_card";
  return $el.memo_dynamic(
    "div",
    attributes,
    children,
    id,
    toList([$s.background($palette.dark.unexpected_aubergine)]),
  );
}

export function documentation_links(attributes, children) {
  let id = "documentation_links";
  return $el.memo_dynamic(
    "div",
    attributes,
    children,
    id,
    toList([$s.display("flex"), $s.justify_content("space-between")]),
  );
}

export function licenses(attributes, children) {
  let id = "licenses";
  return $el.memo_dynamic(
    "div",
    attributes,
    children,
    id,
    toList([$s.display("flex"), $s.gap(px(6))]),
  );
}
