/// <reference types="./sketch.d.mts" />
import * as $float from "../gleam_stdlib/gleam/float.mjs";
import * as $int from "../gleam_stdlib/gleam/int.mjs";
import * as $list from "../gleam_stdlib/gleam/list.mjs";
import * as $result from "../gleam_stdlib/gleam/result.mjs";
import * as $string from "../gleam_stdlib/gleam/string.mjs";
import * as $attribute from "../lustre/lustre/attribute.mjs";
import {
  createCache as create_cache,
  prepareCache as prepare,
  renderCache as render,
} from "./cache.ffi.mjs";
import { Ok, CustomType as $CustomType } from "./gleam.mjs";
import {
  compileClass as compile_class,
  compileClass as compile_style,
  memo,
  toString as to_class_name,
} from "./sketch.ffi.mjs";
import * as $error from "./sketch/error.mjs";
import * as $cache from "./sketch/internals/cache/setup.mjs";
import * as $class from "./sketch/internals/class.mjs";
import * as $style from "./sketch/internals/style.mjs";
import * as $media from "./sketch/media.mjs";
import * as $options from "./sketch/options.mjs";
import * as $size from "./sketch/size.mjs";

export { create_cache, memo, prepare, render, to_class_name };

class Style extends $CustomType {
  constructor(internal) {
    super();
    this.internal = internal;
  }
}

function convert_styles(styles) {
  return $list.map(styles, (item) => { return item.internal; });
}

export function property(field, content) {
  return new Style(new $style.Property(field, content, false));
}

export function width(width) {
  return property("width", $size.to_string(width));
}

export function width_(width) {
  return property("width", width);
}

export function max_width(width) {
  return property("max-width", $size.to_string(width));
}

export function max_width_(width) {
  return property("max-width", width);
}

export function min_width(width) {
  return property("min-width", $size.to_string(width));
}

export function min_width_(width) {
  return property("min-width", width);
}

export function height(height) {
  return property("height", $size.to_string(height));
}

export function height_(height) {
  return property("height", height);
}

export function max_height(height) {
  return property("max-height", $size.to_string(height));
}

export function max_height_(height) {
  return property("max-height", height);
}

export function min_height(height) {
  return property("min-height", $size.to_string(height));
}

export function min_height_(height) {
  return property("min-height", height);
}

export function color(color) {
  return property("color", color);
}

export function font_family(font_family) {
  return property("font-family", font_family);
}

export function font_style(font_style) {
  return property("font-style", font_style);
}

export function font_size(font_size) {
  return property("font-size", $size.to_string(font_size));
}

export function font_size_(font_size) {
  return property("font-size", font_size);
}

export function font_weight(font_weight) {
  return property("font-weight", font_weight);
}

export function letter_spacing(letter_spacing) {
  return property("letter-spacing", letter_spacing);
}

export function line_break(line_break) {
  return property("line-break", line_break);
}

export function line_height(line_height) {
  return property("line-height", line_height);
}

export function text_align(text_align) {
  return property("text-align", text_align);
}

export function text_decoration(text_decoration) {
  return property("text-decoration", text_decoration);
}

export function text_justify(text_justify) {
  return property("text-justify", text_justify);
}

export function text_overflow(text_overflow) {
  return property("text-overflow", text_overflow);
}

export function text_transform(text_transform) {
  return property("text-transform", text_transform);
}

export function white_space(white_space) {
  return property("white-space", white_space);
}

export function white_space_collapse(white_space_collapse) {
  return property("white-space-collapse", white_space_collapse);
}

export function word_break(word_break) {
  return property("word-break", word_break);
}

export function word_spacing(word_spacing) {
  return property("word-spacing", word_spacing);
}

export function word_wrap(word_wrap) {
  return property("word-wrap", word_wrap);
}

export function list_style(list_style) {
  return property("list-style", list_style);
}

export function list_style_image(list_style_image) {
  return property("list-style-image", list_style_image);
}

export function list_style_position(list_style_position) {
  return property("list-style-position", list_style_position);
}

export function list_style_type(list_style_type) {
  return property("list-style-type", list_style_type);
}

export function display(display) {
  return property("display", display);
}

export function z_index(z_index) {
  return property("z-index", $int.to_string(z_index));
}

export function visibility(visibility) {
  return property("visibility", visibility);
}

export function background(background) {
  return property("background", background);
}

export function object_fit(object_fit) {
  return property("object-fit", object_fit);
}

export function object_position(object_position) {
  return property("object-position", object_position);
}

export function pointer_events(pointer_events) {
  return property("pointer-events", pointer_events);
}

export function user_select(user_select) {
  return property("user-select", user_select);
}

export function position(position) {
  return property("position", position);
}

export function outline(outline) {
  return property("outline", outline);
}

export function outline_color(outline_color) {
  return property("outline-color", outline_color);
}

export function outline_offset(outline_offset) {
  return property("outline-offset", outline_offset);
}

export function outline_style(outline_style) {
  return property("outline-style", outline_style);
}

export function outline_width(outline_width) {
  return property("outline-width", outline_width);
}

export function offset(offset) {
  return property("offset", offset);
}

export function offset_anchor(offset_anchor) {
  return property("offset-anchor", offset_anchor);
}

export function offset_distance(offset_distance) {
  return property("offset-distance", offset_distance);
}

export function offset_path(offset_path) {
  return property("offset-path", offset_path);
}

export function offset_position(offset_position) {
  return property("offset-position", offset_position);
}

export function offset_rotate(offset_rotate) {
  return property("offset-rotate", offset_rotate);
}

export function gap(gap) {
  return property("gap", $size.to_string(gap));
}

export function gap_(gap) {
  return property("gap", gap);
}

export function column_gap(column_gap) {
  return property("column-gap", $size.to_string(column_gap));
}

export function row_gap(row_gap) {
  return property("row-gap", $size.to_string(row_gap));
}

export function grid_area(grid_area) {
  return property("grid-area", grid_area);
}

export function grid_column(grid_column) {
  return property("grid-column", grid_column);
}

export function grid_row(grid_row) {
  return property("grid-row", grid_row);
}

export function grid_template(grid_template) {
  return property("grid-template", grid_template);
}

export function grid_auto_columns(grid_auto_columns) {
  return property("grid-auto-columns", grid_auto_columns);
}

export function grid_auto_rows(grid_auto_rows) {
  return property("grid-auto-rows", grid_auto_rows);
}

export function grid_auto_flow(grid_auto_flow) {
  return property("grid-auto-flow", grid_auto_flow);
}

export function grid_template_areas(grid_template_areas) {
  return property("grid-template-areas", grid_template_areas);
}

export function grid_template_columns(grid_template_columns) {
  return property("grid-template-columns", grid_template_columns);
}

export function grid_template_rows(grid_template_rows) {
  return property("grid-template-rows", grid_template_rows);
}

export function align_content(align) {
  return property("align-content", align);
}

export function align_items(align) {
  return property("align-items", align);
}

export function align_self(align) {
  return property("align-self", align);
}

export function align_tracks(align) {
  return property("align-tracks", align);
}

export function justify_content(justify) {
  return property("justify-content", justify);
}

export function justify_items(justify) {
  return property("justify-items", justify);
}

export function justify_self(justify) {
  return property("justify-self", justify);
}

export function justify_tracks(justify) {
  return property("justify-tracks", justify);
}

export function place_content(place) {
  return property("place-content", place);
}

export function place_items(place) {
  return property("place-items", place);
}

export function place_self(place) {
  return property("place-self", place);
}

export function animation(animation) {
  return property("animation", animation);
}

export function animation_name(animation) {
  return property("animation-name", animation);
}

export function animation_duration(animation) {
  return property("animation-duration", animation);
}

export function animation_timing_function(animation) {
  return property("animation-timing-function", animation);
}

export function animation_delay(animation) {
  return property("animation-delay", animation);
}

export function animation_iteration_count(animation) {
  return property("animation-iteration-count", animation);
}

export function animation_direction(animation) {
  return property("animation-direction", animation);
}

export function animation_fill_mode(animation) {
  return property("animation-fill-mode", animation);
}

export function animation_play_state(animation) {
  return property("animation-play-state", animation);
}

export function transition(transition) {
  return property("transition", transition);
}

export function translate(translate) {
  return property("translate", translate);
}

export function transform(transform) {
  return property("transform", transform);
}

export function transform_box(transform_box) {
  return property("transform-box", transform_box);
}

export function transform_origin(transform_origin) {
  return property("transform-origin", transform_origin);
}

export function transform_style(transform_style) {
  return property("transform-style", transform_style);
}

export function appearance(appearance) {
  return property("appearance", appearance);
}

export function filter(filter) {
  return property("filter", filter);
}

export function aspect_ratio(aspect_ratio) {
  return property("aspect-ratio", aspect_ratio);
}

export function top(size) {
  return property("top", $size.to_string(size));
}

export function bottom(size) {
  return property("bottom", $size.to_string(size));
}

export function right(size) {
  return property("right", $size.to_string(size));
}

export function left(size) {
  return property("left", $size.to_string(size));
}

export function top_(size) {
  return property("top", size);
}

export function bottom_(size) {
  return property("bottom", size);
}

export function right_(size) {
  return property("right", size);
}

export function left_(size) {
  return property("left", size);
}

export function box_shadow(box_shadow) {
  return property("box-shadow", box_shadow);
}

export function box_sizing(box_sizing) {
  return property("box-sizing", box_sizing);
}

export function overflow(overflow) {
  return property("overflow", overflow);
}

export function overflow_x(overflow_x) {
  return property("overflow-x", overflow_x);
}

export function overflow_y(overflow_y) {
  return property("overflow-y", overflow_y);
}

export function direction(direction) {
  return property("direction", direction);
}

export function flex(flex) {
  return property("flex", flex);
}

export function flex_basis(flex_basis) {
  return property("flex-basis", flex_basis);
}

export function flex_direction(flex_direction) {
  return property("flex-direction", flex_direction);
}

export function flex_grow(flex_grow) {
  return property("flex-grow", flex_grow);
}

export function border(border) {
  return property("border", border);
}

export function border_top(border_top) {
  return property("border-top", border_top);
}

export function border_bottom(border_bottom) {
  return property("border-bottom", border_bottom);
}

export function border_right(border_right) {
  return property("border-right", border_right);
}

export function border_left(border_left) {
  return property("border-left", border_left);
}

export function border_radius(border_radius) {
  return property("border-radius", $size.to_string(border_radius));
}

export function border_radius_(border_radius) {
  return property("border-radius", border_radius);
}

export function border_top_right_radius(border_top_right_radius) {
  let _pipe = $size.to_string(border_top_right_radius);
  return ((_capture) => { return property("border-top-right-radius", _capture); })(
    _pipe,
  );
}

export function border_top_left_radius(border_top_left_radius) {
  let _pipe = $size.to_string(border_top_left_radius);
  return ((_capture) => { return property("border-top-left-radius", _capture); })(
    _pipe,
  );
}

export function border_bottom_right_radius(border_bottom_right_radius) {
  let _pipe = $size.to_string(border_bottom_right_radius);
  return ((_capture) => {
    return property("border-bottom-right-radius", _capture);
  })(_pipe);
}

export function border_bottom_left_radius(border_bottom_left_radius) {
  let _pipe = $size.to_string(border_bottom_left_radius);
  return ((_capture) => {
    return property("border-bottom-left-radius", _capture);
  })(_pipe);
}

export function border_top_right_radius_(border_top_right_radius) {
  return property("border-top-right-radius", border_top_right_radius);
}

export function border_top_left_radius_(border_top_left_radius) {
  return property("border-top-left-radius", border_top_left_radius);
}

export function border_bottom_right_radius_(border_bottom_right_radius) {
  return property("border-bottom-right-radius", border_bottom_right_radius);
}

export function border_bottom_left_radius_(border_bottom_left_radius) {
  return property("border-bottom-left-radius", border_bottom_left_radius);
}

export function padding(padding) {
  return property("padding", $size.to_string(padding));
}

export function padding_(padding) {
  return property("padding", padding);
}

export function padding_top(padding) {
  return property("padding-top", $size.to_string(padding));
}

export function padding_bottom(padding) {
  return property("padding-bottom", $size.to_string(padding));
}

export function padding_right(padding) {
  return property("padding-right", $size.to_string(padding));
}

export function padding_left(padding) {
  return property("padding-left", $size.to_string(padding));
}

export function margin(margin) {
  return property("margin", $size.to_string(margin));
}

export function margin_(margin) {
  return property("margin", margin);
}

export function margin_top(margin) {
  return property("margin-top", $size.to_string(margin));
}

export function margin_bottom(margin) {
  return property("margin-bottom", $size.to_string(margin));
}

export function margin_right(margin) {
  return property("margin-right", $size.to_string(margin));
}

export function margin_left(margin) {
  return property("margin-left", $size.to_string(margin));
}

export function cursor(cursor) {
  return property("cursor", cursor);
}

export function float(float) {
  return property("float", float);
}

export function opacity(opacity) {
  return property("opacity", $float.to_string(opacity));
}

export function media(query, styles) {
  let media_selector = $media.to_string(query);
  return new Style(new $style.Media(media_selector, convert_styles(styles)));
}

export function pseudo_selector(value, styles) {
  return new Style(new $style.PseudoSelector(value, convert_styles(styles)));
}

export function placeholder(styles) {
  return pseudo_selector("::placeholder", styles);
}

export function hover(styles) {
  return pseudo_selector(":hover", styles);
}

export function active(styles) {
  return pseudo_selector(":active", styles);
}

export function focus(styles) {
  return pseudo_selector(":focus", styles);
}

export function focus_visible(styles) {
  return pseudo_selector(":focus-visible", styles);
}

export function focus_within(styles) {
  return pseudo_selector(":focus-within", styles);
}

export function enabled(styles) {
  return pseudo_selector(":enabled", styles);
}

export function disabled(styles) {
  return pseudo_selector(":disabled", styles);
}

export function read_only(styles) {
  return pseudo_selector(":read-only", styles);
}

export function read_write(styles) {
  return pseudo_selector(":read-write", styles);
}

export function checked(styles) {
  return pseudo_selector(":checked", styles);
}

export function blank(styles) {
  return pseudo_selector(":blank", styles);
}

export function valid(styles) {
  return pseudo_selector(":valid", styles);
}

export function invalid(styles) {
  return pseudo_selector(":invalid", styles);
}

export function required(styles) {
  return pseudo_selector(":required", styles);
}

export function optional(styles) {
  return pseudo_selector(":optional", styles);
}

export function link(styles) {
  return pseudo_selector(":link", styles);
}

export function visited(styles) {
  return pseudo_selector(":visited", styles);
}

export function target(styles) {
  return pseudo_selector(":target", styles);
}

export function nth_child(selector, styles) {
  return pseudo_selector($string.append(":nth-child", selector), styles);
}

export function nth_last_child(selector, styles) {
  return pseudo_selector($string.append(":nth-last-child", selector), styles);
}

export function nth_of_type(selector, styles) {
  return pseudo_selector($string.append(":nth-of-type", selector), styles);
}

export function nth_last_of_type(selector, styles) {
  return pseudo_selector($string.append(":nth-last-of-type", selector), styles);
}

export function first_child(styles) {
  return pseudo_selector(":first-child", styles);
}

export function last_child(styles) {
  return pseudo_selector(":last-child", styles);
}

export function only_child(styles) {
  return pseudo_selector(":only-child", styles);
}

export function first_of_type(styles) {
  return pseudo_selector(":first-of-type", styles);
}

export function last_of_type(styles) {
  return pseudo_selector(":last-of-type", styles);
}

export function only_of_type(styles) {
  return pseudo_selector(":only-of-type", styles);
}

export function important(style) {
  if (style instanceof Style && style.internal instanceof $style.Property) {
    let key = style.internal.key;
    let value = style.internal.value;
    return new Style(new $style.Property(key, value, true));
  } else {
    let any = style;
    return any;
  }
}

export function compose(class$) {
  let _pipe = class$;
  let _pipe$1 = to_class_name(_pipe);
  let _pipe$2 = new $style.ClassName(_pipe$1);
  return new Style(_pipe$2);
}

export function class$(styles) {
  let _pipe = styles;
  let _pipe$1 = convert_styles(_pipe);
  return compile_class(_pipe$1);
}

export function dynamic(id, styles) {
  let _pipe = styles;
  let _pipe$1 = convert_styles(_pipe);
  return compile_style(_pipe$1, id);
}

export function to_lustre(class$) {
  let _pipe = class$;
  let _pipe$1 = to_class_name(_pipe);
  let _pipe$2 = $string.split(_pipe$1, " ");
  let _pipe$3 = $list.map(_pipe$2, (value) => { return [value, true]; });
  return $attribute.classes(_pipe$3);
}

export function lustre_setup(options) {
  return $result.then$(
    create_cache(options),
    (cache) => {
      return new Ok(
        (view) => {
          return (model) => {
            prepare(cache);
            let el = view(model);
            let $ = render(cache);
            
            return el;
          };
        },
      );
    },
  );
}
