/// <reference types="./links.d.mts" />
import { toList } from "../../../gleam.mjs";

export const links = /* @__PURE__ */ toList([
  [
    "Gleam Resources",
    /* @__PURE__ */ toList([
      ["https://gleam.run/", "Homepage"],
      ["https://packages.gleam.run/", "Gleam Packages"],
      ["https://gleam.run/documentation/", "Docs"],
      ["https://tour.gleam.run/", "Language Tour"],
      ["https://github.com/gleam-lang", "Code"],
    ]),
  ],
  [
    "Gleam Community",
    /* @__PURE__ */ toList([
      ["https://gleam.run/news/", "News"],
      ["https://gleamweekly.com/", "Gleam Weekly"],
      ["https://gleam.run/community/", "Community"],
      ["https://github.com/sponsors/lpil", "Sponsor Gleam"],
    ]),
  ],
  [
    "Gloogle",
    /* @__PURE__ */ toList([
      ["https://github.com/sponsors/ghivert", "Sponsor"],
      ["https://github.com/ghivert/gloogle", "Code"],
    ]),
  ],
]);
