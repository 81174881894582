/// <reference types="./lustre.d.mts" />
import * as $list from "../../gleam_stdlib/gleam/list.mjs";
import * as $result from "../../gleam_stdlib/gleam/result.mjs";
import * as $element from "../../lustre/lustre/element.mjs";
import * as $html from "../../lustre/lustre/element/html.mjs";
import * as $vdom from "../../lustre/lustre/internals/vdom.mjs";
import { toList } from "../gleam.mjs";
import * as $sketch from "../sketch.mjs";
import * as $options from "../sketch/options.mjs";

export function setup(options) {
  return $sketch.create_cache(options);
}

export function compose(view, cache) {
  return (model) => {
    $sketch.prepare(cache);
    let el = view(model);
    let $ = $sketch.render(cache);
    
    return el;
  };
}

function contains_head(el) {
  if (el instanceof $vdom.Element && el.tag === "head") {
    return true;
  } else if (el instanceof $vdom.Element) {
    let children = el.children;
    return $list.fold(
      children,
      false,
      (acc, val) => { return acc || contains_head(val); },
    );
  } else {
    return false;
  }
}

function put_in_head(el, content) {
  if (el instanceof $vdom.Element && el.tag === "head") {
    let k = el.key;
    let n = el.namespace;
    let a = el.attrs;
    let children = el.children;
    let s = el.self_closing;
    let v = el.void;
    let _pipe = children;
    let _pipe$1 = $list.append(
      _pipe,
      toList([$html.style(toList([]), content)]),
    );
    return ((_capture) => {
      return new $vdom.Element(k, n, "head", a, _capture, s, v);
    })(_pipe$1);
  } else if (el instanceof $vdom.Element && el.tag === "html") {
    let k = el.key;
    let n = el.namespace;
    let a = el.attrs;
    let children = el.children;
    let s = el.self_closing;
    let v = el.void;
    let _pipe = children;
    let _pipe$1 = $list.map(
      _pipe,
      (child) => { return put_in_head(child, content); },
    );
    return ((_capture) => {
      return new $vdom.Element(k, n, "html", a, _capture, s, v);
    })(_pipe$1);
  } else {
    let node = el;
    return node;
  }
}
