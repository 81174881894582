/// <reference types="./helpers.d.mts" />
import * as $function from "../../../gleam_stdlib/gleam/function.mjs";
import * as $string from "../../../gleam_stdlib/gleam/string.mjs";
import * as $h from "../../../lustre/lustre/element/html.mjs";
import { toList } from "../../gleam.mjs";

export function newline() {
  return $h.text("\n");
}

export function idt(indent) {
  return $h.text($string.repeat(" ", indent));
}

export function hexdocs_link(package$, version, module, name) {
  let join = $function.flip($string.join);
  let base = join(
    "/",
    toList(["https://hexdocs.pm", package$, version, module]),
  );
  return (base + ".html#") + name;
}
