/// <reference types="./types.d.mts" />
import * as $a from "../../../lustre/lustre/attribute.mjs";
import * as $h from "../../../lustre/lustre/element/html.mjs";
import * as $palette from "../../frontend/colors/palette.mjs";
import { toList } from "../../gleam.mjs";

function span(text, color) {
  return $h.span(
    toList([$a.style(toList([["color", color]]))]),
    toList([$h.text(text)]),
  );
}

export function keyword(text) {
  return span(text, "var(--hljs-hue-3)");
}

export function fun(text) {
  return span(text, "var(--hljs-hue-2)");
}

export function label(text) {
  return span(text, "var(--hljs-hue-5)");
}

export function type_(text) {
  return span(text, "var(--hljs-hue-6-2)");
}

export function variable(text) {
  return span(text, "var(--hljs-hue-4)");
}

export function dark_white(text) {
  return span(text, $palette.dark.dark_white);
}

export function white(text) {
  return span(text, "var(--text-color)");
}
