/// <reference types="./signature.d.mts" />
import * as $dynamic from "../../gleam_stdlib/gleam/dynamic.mjs";
import * as $int from "../../gleam_stdlib/gleam/int.mjs";
import * as $list from "../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../gleam_stdlib/gleam/option.mjs";
import * as $result from "../../gleam_stdlib/gleam/result.mjs";
import * as $string from "../../gleam_stdlib/gleam/string.mjs";
import { Error, toList, prepend as listPrepend, CustomType as $CustomType } from "../gleam.mjs";

export class Tuple extends $CustomType {
  constructor(width, elements) {
    super();
    this.width = width;
    this.elements = elements;
  }
}

export class Fn extends $CustomType {
  constructor(width, parameters, return$) {
    super();
    this.width = width;
    this.parameters = parameters;
    this.return = return$;
  }
}

export class Variable extends $CustomType {
  constructor(width, id) {
    super();
    this.width = width;
    this.id = id;
  }
}

export class Named extends $CustomType {
  constructor(width, name, package$, module, parameters, ref) {
    super();
    this.width = width;
    this.name = name;
    this.package = package$;
    this.module = module;
    this.parameters = parameters;
    this.ref = ref;
  }
}

export class Parameter extends $CustomType {
  constructor(width, label, type_) {
    super();
    this.width = width;
    this.label = label;
    this.type_ = type_;
  }
}

export class TypeConstructor extends $CustomType {
  constructor(width, params_width, documentation, name, parameters) {
    super();
    this.width = width;
    this.params_width = params_width;
    this.documentation = documentation;
    this.name = name;
    this.parameters = parameters;
  }
}

export class Function extends $CustomType {
  constructor(width, params_width, name, return$, parameters) {
    super();
    this.width = width;
    this.params_width = params_width;
    this.name = name;
    this.return = return$;
    this.parameters = parameters;
  }
}

export class Constant extends $CustomType {
  constructor(width, type_) {
    super();
    this.width = width;
    this.type_ = type_;
  }
}

export class TypeAlias extends $CustomType {
  constructor(width, parameters, alias) {
    super();
    this.width = width;
    this.parameters = parameters;
    this.alias = alias;
  }
}

export class TypeDefinition extends $CustomType {
  constructor(parameters, constructors) {
    super();
    this.parameters = parameters;
    this.constructors = constructors;
  }
}

function decode_variable(dyn) {
  return $dynamic.decode1(
    (a) => { return new Variable(1, a); },
    $dynamic.field("id", $dynamic.int),
  )(dyn);
}

function decode_fn(dyn) {
  return $dynamic.decode2(
    (a, b) => {
      let width = (() => {
        let _pipe = listPrepend(b, a);
        let _pipe$1 = $list.fold(
          _pipe,
          0,
          (acc, val) => { return val.width + acc; },
        );
        return $int.add(_pipe$1, $int.max($list.length(a) - 1, 0) * 2 + 8);
      })();
      return new Fn(width, a, b);
    },
    $dynamic.field("params", $dynamic.list(decode_type)),
    $dynamic.field("return", decode_type),
  )(dyn);
}

function decode_type(dyn) {
  return $result.try$(
    $dynamic.field("kind", $dynamic.string)(dyn),
    (res) => {
      if (res === "variable") {
        return decode_variable(dyn);
      } else if (res === "fn") {
        return decode_fn(dyn);
      } else if (res === "tuple") {
        return decode_tuple(dyn);
      } else if (res === "named") {
        return decode_named(dyn);
      } else {
        return new Error(toList([new $dynamic.DecodeError("", "", toList([]))]));
      }
    },
  );
}

function decode_tuple(dyn) {
  return $dynamic.decode1(
    (a) => {
      let width = (() => {
        let _pipe = $list.fold(a, 0, (acc, val) => { return val.width + acc; });
        return $int.add(_pipe, $int.max($list.length(a) - 1, 0) * 2 + 3);
      })();
      return new Tuple(width, a);
    },
    $dynamic.field("elements", $dynamic.list(decode_type)),
  )(dyn);
}

function decode_named(dyn) {
  return $dynamic.decode5(
    (a, b, c, d, e) => {
      let params_width = $list.fold(
        d,
        0,
        (acc, val) => { return val.width + acc; },
      );
      let width = $string.length(a) + (() => {
        if (params_width === 0) {
          return 0;
        } else {
          let value = params_width;
          return (value + $int.max($list.length(d) - 1, 0) * 2) + 8;
        }
      })();
      return new Named(width, a, b, c, d, e);
    },
    $dynamic.field("name", $dynamic.string),
    $dynamic.field("package", $dynamic.string),
    $dynamic.field("module", $dynamic.string),
    $dynamic.field("parameters", $dynamic.list(decode_type)),
    $dynamic.field("ref", $dynamic.optional($dynamic.string)),
  )(dyn);
}

function decode_parameter(dyn) {
  return $dynamic.decode2(
    (a, b) => {
      let width = (() => {
        let $ = $string.length($option.unwrap(a, ""));
        if ($ === 0) {
          return 0;
        } else {
          let value = $;
          return value + 2;
        }
      })() + b.width;
      return new Parameter(width, a, b);
    },
    $dynamic.field("label", $dynamic.optional($dynamic.string)),
    $dynamic.field("type", decode_type),
  )(dyn);
}

function decode_constant(dyn) {
  return $dynamic.decode1(
    (a) => {
      let width = a.width;
      return new Constant(width, a);
    },
    $dynamic.field("type", decode_type),
  )(dyn);
}

function decode_function(dyn) {
  return $dynamic.decode3(
    (a, b, c) => {
      let params_width = (() => {
        let _pipe = c;
        let _pipe$1 = $list.fold(
          _pipe,
          0,
          (acc, val) => { return val.width + acc; },
        );
        return $int.add(_pipe$1, $int.max($list.length(c) - 1, 0) * 2 + 2);
      })();
      let width = (() => {
        let _pipe = params_width;
        return $int.add(_pipe, (b.width + $string.length(a)) + 6);
      })();
      return new Function(width, params_width, a, b, c);
    },
    $dynamic.field("name", $dynamic.string),
    $dynamic.field("return", decode_type),
    $dynamic.field("parameters", $dynamic.list(decode_parameter)),
  )(dyn);
}

function decode_type_alias(dyn) {
  return $dynamic.decode2(
    (a, b) => {
      let width = ((a * 2 + 2) + b.width) + 3;
      return new TypeAlias(width, a, b);
    },
    $dynamic.field("parameters", $dynamic.int),
    $dynamic.field("alias", decode_type),
  )(dyn);
}

function decode_constructors(dyn) {
  return $dynamic.decode3(
    (a, b, c) => {
      let params_width = (() => {
        let _pipe = c;
        let _pipe$1 = $list.fold(
          _pipe,
          0,
          (acc, val) => { return val.width + acc; },
        );
        return $int.add(_pipe$1, $int.max($list.length(c) - 1, 0) * 2 + 2);
      })();
      let width = (() => {
        let _pipe = params_width;
        return $int.add(_pipe, $string.length(b) + 1);
      })();
      return new TypeConstructor(width, params_width, a, b, c);
    },
    $dynamic.field("documentation", $dynamic.optional($dynamic.string)),
    $dynamic.field("name", $dynamic.string),
    $dynamic.field("parameters", $dynamic.list(decode_parameter)),
  )(dyn);
}

function decode_type_definition(dyn) {
  return $dynamic.decode2(
    (var0, var1) => { return new TypeDefinition(var0, var1); },
    $dynamic.field("parameters", $dynamic.int),
    $dynamic.field("constructors", $dynamic.list(decode_constructors)),
  )(dyn);
}

export function decode_signature(dyn) {
  return $result.try$(
    $dynamic.field("kind", $dynamic.string)(dyn),
    (res) => {
      if (res === "constant") {
        return decode_constant(dyn);
      } else if (res === "function") {
        return decode_function(dyn);
      } else if (res === "type-alias") {
        return decode_type_alias(dyn);
      } else if (res === "type-definition") {
        return decode_type_definition(dyn);
      } else {
        return new Error(toList([new $dynamic.DecodeError("", "", toList([]))]));
      }
    },
  );
}
