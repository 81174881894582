/// <reference types="./lazy.d.mts" />
import * as $dict from "../../gleam_stdlib/gleam/dict.mjs";
import * as $lustre from "../../lustre/lustre.mjs";
import * as $attribute from "../../lustre/lustre/attribute.mjs";
import * as $effect from "../../lustre/lustre/effect.mjs";
import * as $element from "../../lustre/lustre/element.mjs";
import * as $event from "../../lustre/lustre/event.mjs";
import { Ok, toList, CustomType as $CustomType } from "../gleam.mjs";
import * as $coerce from "../gleam/coerce.mjs";
import { coerce } from "../gleam/coerce.mjs";
import { coerceEvent as coerce_event } from "../gloogle.ffi.mjs";

class UpdateContent extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class OnContentMessage extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

function update(model, msg) {
  if (msg instanceof UpdateContent) {
    let content = msg[0];
    return [content, $effect.none()];
  } else {
    let msg$1 = msg[0];
    return [model, $event.emit("msg", coerce(msg$1))];
  }
}

function on_attribute_change() {
  let update_content = (dyn) => {
    return new Ok(new UpdateContent(coerce(dyn)));
  };
  return $dict.from_list(toList([["content", update_content]]));
}

function on_coerce(value) {
  return new Ok(coerce_event(value));
}

const tag_name = "lazy-node";

export function setup() {
  let init = (_) => { return [$element.none(), $effect.none()]; };
  let view = (_capture) => {
    return $element.map(
      _capture,
      (var0) => { return new OnContentMessage(var0); },
    );
  };
  let _pipe = on_attribute_change();
  let _pipe$1 = ((_capture) => {
    return $lustre.component(init, update, view, _capture);
  })(_pipe);
  return $lustre.register(_pipe$1, tag_name);
}

export function lazy(content) {
  let content$1 = $attribute.property("content", content);
  return $element.element(
    tag_name,
    toList([content$1, $event.on("msg", on_coerce)]),
    toList([]),
  );
}
