/// <reference types="./documentation.d.mts" />
import * as $list from "../../../gleam_stdlib/gleam/list.mjs";
import * as $string from "../../../gleam_stdlib/gleam/string.mjs";
import * as $a from "../../../lustre/lustre/attribute.mjs";
import * as $h from "../../../lustre/lustre/element/html.mjs";
import { toList } from "../../gleam.mjs";
import { convert as converter } from "../../markdown.ffi.mjs";

function remove_leading_space(str) {
  if (str.startsWith(" ")) {
    let rest = str.slice(1);
    return rest;
  } else {
    let str$1 = str;
    return str$1;
  }
}

export function view(document) {
  let content = (() => {
    let _pipe = document;
    let _pipe$1 = $string.split(_pipe, "\n");
    let _pipe$2 = $list.map(_pipe$1, remove_leading_space);
    let _pipe$3 = $string.join(_pipe$2, "\n");
    let _pipe$4 = converter(_pipe$3);
    return ((_capture) => {
      return $a.attribute("dangerous-unescaped-html", _capture);
    })(_pipe$4);
  })();
  return $h.div(toList([content, $a.class$("documentation")]), toList([]));
}
