/// <reference types="./implementations.d.mts" />
import * as $dynamic from "../../gleam_stdlib/gleam/dynamic.mjs";
import { CustomType as $CustomType } from "../gleam.mjs";

export class Implementations extends $CustomType {
  constructor(gleam, uses_erlang_externals, uses_javascript_externals) {
    super();
    this.gleam = gleam;
    this.uses_erlang_externals = uses_erlang_externals;
    this.uses_javascript_externals = uses_javascript_externals;
  }
}

export function decode_implementations(dyn) {
  return $dynamic.decode3(
    (var0, var1, var2) => { return new Implementations(var0, var1, var2); },
    $dynamic.field("gleam", $dynamic.bool),
    $dynamic.field("uses_erlang_externals", $dynamic.bool),
    $dynamic.field("uses_javascript_externals", $dynamic.bool),
  )(dyn);
}
