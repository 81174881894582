/// <reference types="./update.d.mts" />
import * as $effect from "../../lustre/lustre/effect.mjs";
import { toList } from "../gleam.mjs";

export function effect(model, effect) {
  return [model, effect];
}

export function none(model) {
  return [model, $effect.none()];
}

export function effects(model, effects) {
  return [model, $effect.batch(effects)];
}

export function add_effect(tuple, effect) {
  let model = tuple[0];
  let fst_effect = tuple[1];
  return [model, $effect.batch(toList([fst_effect, effect]))];
}
