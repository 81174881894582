/// <reference types="./body.d.mts" />
import * as $birl from "../../../../birl/birl.mjs";
import * as $bool from "../../../../gleam_stdlib/gleam/bool.mjs";
import * as $dict from "../../../../gleam_stdlib/gleam/dict.mjs";
import * as $float from "../../../../gleam_stdlib/gleam/float.mjs";
import * as $int from "../../../../gleam_stdlib/gleam/int.mjs";
import * as $list from "../../../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../../../gleam_stdlib/gleam/option.mjs";
import * as $result from "../../../../gleam_stdlib/gleam/result.mjs";
import * as $string from "../../../../gleam_stdlib/gleam/string.mjs";
import * as $a from "../../../../lustre/lustre/attribute.mjs";
import * as $el from "../../../../lustre/lustre/element.mjs";
import * as $h from "../../../../lustre/lustre/element/html.mjs";
import * as $e from "../../../../lustre/lustre/event.mjs";
import * as $chart from "../../../chart.mjs";
import { Dataset } from "../../../chart.mjs";
import * as $model from "../../../data/model.mjs";
import * as $msg from "../../../data/msg.mjs";
import * as $search_result from "../../../data/search_result.mjs";
import * as $icons from "../../../frontend/icons.mjs";
import * as $images from "../../../frontend/images.mjs";
import * as $router from "../../../frontend/router.mjs";
import * as $frontend_strings from "../../../frontend/strings.mjs";
import * as $search_input from "../../../frontend/view/search_input/search_input.mjs";
import { toList, prepend as listPrepend, divideFloat } from "../../../gleam.mjs";
import * as $lazy from "../../../lustre/lazy.mjs";

function view_search_input(model) {
  let has_content = (() => {
    let _pipe = model.input;
    let _pipe$1 = $string.length(_pipe);
    return ((input) => { return input !== 0; })(_pipe$1);
  })();
  return $h.form(
    toList([$a.class$("search-wrapper"), $e.on_submit(new $msg.SubmitSearch())]),
    toList([
      $h.div(
        toList([$a.class$("search-title-wrapper")]),
        toList([
          $h.div(
            toList([$a.class$("search-title")]),
            toList([
              $h.img(
                toList([
                  $a.class$("search-lucy"),
                  $a.style(toList([["width", "40px"]])),
                  $a.src("/images/lucy.svg"),
                ]),
              ),
              $h.div(
                toList([$a.class$("search-title-with-hint")]),
                toList([
                  $h.text("Gloogle"),
                  $h.div(
                    toList([$a.class$("pre-alpha-title")]),
                    toList([$h.text("RC")]),
                  ),
                ]),
              ),
            ]),
          ),
          $h.text($frontend_strings.gloogle_description),
        ]),
      ),
      $search_input.view(model.loading, model.input, false),
      $h.input(
        toList([
          $a.class$("search-submit"),
          $a.type_("submit"),
          $a.value("Submit"),
          $a.disabled(model.loading || !has_content),
        ]),
      ),
    ]),
  );
}

function empty_state(image, title, content) {
  return $h.div(
    toList([$a.class$("empty-state")]),
    toList([
      $h.img(toList([$a.class$("empty-state-lucy"), $a.src(image)])),
      $h.div(
        toList([$a.class$("empty-state-titles")]),
        toList([
          $h.div(toList([]), toList([$h.text(title)])),
          $h.div(
            toList([$a.class$("empty-state-subtitle")]),
            toList([$h.text(content)]),
          ),
        ]),
      ),
    ]),
  );
}

export function view_trending(model) {
  return $el.none();
}

function checkbox(active, msg, name) {
  let bg = (() => {
    if (active) {
      return "#ffaff3";
    } else {
      return "var(--input-background)";
    }
  })();
  return $h.label(
    toList([$a.class$("sidebar-filter-line")]),
    toList([
      $el.fragment(
        toList([
          $h.div(
            toList([
              $a.class$("sidebar-checkbox-1"),
              $a.style(toList([["background", bg]])),
            ]),
            toList([]),
          ),
          $h.input(
            toList([
              $a.class$("sidebar-checkbox-2"),
              $a.type_("checkbox"),
              $a.checked(active),
              $e.on_check(
                (_capture) => { return new $msg.OnCheckFilter(msg, _capture); },
              ),
            ]),
          ),
        ]),
      ),
      $h.div(
        toList([$a.class$("sidebar-filter-name")]),
        toList([$el.text(name)]),
      ),
    ]),
  );
}

function sidebar_external_link(href, title, icon) {
  let class$ = $a.class$("sidebar-link-wrapper");
  return $h.a(
    toList([
      class$,
      $a.href(href),
      $a.target("_blank"),
      $a.rel("noreferrer noopener"),
    ]),
    toList([
      $h.div(toList([$a.class$("sidebar-icon")]), toList([icon])),
      $h.div(toList([$a.class$("sidebar-link")]), toList([$el.text(title)])),
    ]),
  );
}

function sidebar_link(href, title, icon) {
  let class$ = $a.class$("sidebar-link-wrapper");
  return $h.a(
    toList([class$, $a.href(href)]),
    toList([
      $h.div(toList([$a.class$("sidebar-icon")]), toList([icon])),
      $h.div(toList([$a.class$("sidebar-link")]), toList([$el.text(title)])),
    ]),
  );
}

function sidebar(model) {
  return $bool.guard(
    model.is_mobile,
    $el.none(),
    () => {
      let disabled = (() => {
        let $ = model.route;
        if ($ instanceof $router.Search) {
          return $a.style(toList([["opacity", "1"]]));
        } else {
          return $a.style(
            toList([["opacity", "0.3"], ["pointer-events", "none"]]),
          );
        }
      })();
      return $h.main(
        toList([$a.class$("search-sidebar")]),
        toList([
          $h.a(
            toList([$a.class$("sidebar-title"), $a.href("/")]),
            toList([
              $h.img(
                toList([
                  $a.class$("search-lucy"),
                  $a.style(toList([["width", "32px"]])),
                  $a.src("/images/lucy.svg"),
                ]),
              ),
              $h.form(
                toList([$a.class$("sidebar-title-inside")]),
                toList([$h.text("Gloogle")]),
              ),
            ]),
          ),
          $h.form(
            toList([$e.on_submit(new $msg.SubmitSearch())]),
            toList([$search_input.view(model.loading, model.input, true)]),
          ),
          $h.div(
            toList([$a.class$("sidebar-filter"), disabled]),
            toList([$el.text("Filters")]),
          ),
          $h.div(
            toList([$a.class$("sidebar-filters"), disabled]),
            toList([
              checkbox(model.keep_functions, new $msg.Functions(), "Functions"),
              checkbox(model.keep_types, new $msg.Types(), "Types"),
              checkbox(model.keep_aliases, new $msg.Aliases(), "Aliases"),
              $h.div(toList([$a.class$("filter-separator")]), toList([])),
              checkbox(
                model.keep_documented,
                new $msg.Documented(),
                "Documented",
              ),
              checkbox(
                model.show_old_packages,
                new $msg.ShowOldPackages(),
                "Show old versions",
              ),
              $h.div(
                toList([$a.class$("filter-separator"), disabled]),
                toList([]),
              ),
              checkbox(
                model.show_documentation_search,
                new $msg.DocumentationSearch(),
                "Documentation Search",
              ),
              checkbox(
                model.show_vector_search,
                new $msg.VectorSearch(),
                "Vector Search",
              ),
            ]),
          ),
          $h.div(toList([$a.class$("sidebar-spacer"), disabled]), toList([])),
          $h.div(
            toList([$a.class$("sidebar-links")]),
            toList([
              sidebar_link("/analytics", "Analytics", $icons.trends()),
              sidebar_external_link(
                "https://github.com/sponsors/ghivert",
                "Sponsor",
                $icons.heart(),
              ),
            ]),
          ),
        ]),
      );
    },
  );
}

function format_huge_number(number) {
  let number$1 = $int.to_float(number);
  let g = divideFloat(number$1, 1_000_000_000.0);
  let m = divideFloat(number$1, 1_000_000.0);
  let k = divideFloat(number$1, 1000.0);
  if (g > 1.0) {
    return (() => {
      let _pipe = $float.to_string(g);
      return $string.slice(_pipe, 0, 6);
    })() + " G";
  } else if (m > 1.0) {
    return (() => {
      let _pipe = $float.to_string(m);
      return $string.slice(_pipe, 0, 6);
    })() + " M";
  } else if (k > 10.0) {
    return (() => {
      let _pipe = $float.to_string(k);
      return $string.slice(_pipe, 0, 6);
    })() + " K";
  } else {
    let _pipe = $float.round(number$1);
    return $int.to_string(_pipe);
  }
}

function analytics_box(title, count) {
  return $h.div(
    toList([$a.class$("analytics-box")]),
    toList([
      $h.div(toList([$a.class$("analytics-title")]), toList([$h.text(title)])),
      $h.text(format_huge_number(count)),
    ]),
  );
}

function popularity_chart(model) {
  let data = $list.filter(
    model.popular,
    (p) => { return p.repository !== "https://github.com/gleam-lang/gleam"; },
  );
  return $bool.guard(
    $list.is_empty(data),
    $el.none(),
    () => {
      return $chart.bar_chart(
        "#ff851b",
        (() => {
          let acc = new Dataset(toList([]), toList([]));
          return $list.fold(
            $list.reverse(data),
            acc,
            (_use0, package$) => {
              let dates = _use0.labels;
              let value = _use0.data;
              let $ = package$.popularity;
              if ($ instanceof $option.None) {
                return new Dataset(dates, value);
              } else {
                let popularity = $[0];
                let label = package$.name;
                return new Dataset(
                  listPrepend(label, dates),
                  listPrepend(popularity, value),
                );
              }
            },
          );
        })(),
      );
    },
  );
}

function ranked_chart(model) {
  let data = $list.filter(
    model.ranked,
    (p) => { return (p.name !== "gleam_stdlib") && (p.name !== "gleeunit"); },
  );
  return $bool.guard(
    $list.is_empty(data),
    $el.none(),
    () => {
      return $chart.bar_chart(
        "#ffaff3",
        (() => {
          let acc = new Dataset(toList([]), toList([]));
          return $list.fold(
            $list.reverse(data),
            acc,
            (_use0, package$) => {
              let dates = _use0.labels;
              let value = _use0.data;
              let label = package$.name;
              return new Dataset(
                listPrepend(label, dates),
                listPrepend(package$.rank, value),
              );
            },
          );
        })(),
      );
    },
  );
}

function analytics_chart(model) {
  let data = model.timeseries;
  return $bool.guard(
    $list.is_empty(data),
    $el.none(),
    () => {
      return $chart.line_chart(
        (() => {
          let acc = new Dataset(toList([]), toList([]));
          return $list.fold(
            data,
            acc,
            (_use0, _use1) => {
              let dates = _use0.labels;
              let value = _use0.data;
              let count = _use1[0];
              let date = _use1[1];
              let day = $birl.get_day(date);
              let label = (() => {
                let _pipe = toList([day.year, day.month, day.date]);
                let _pipe$1 = $list.map(_pipe, $int.to_string);
                return $string.join(_pipe$1, "/");
              })();
              return new Dataset(
                listPrepend(label, dates),
                listPrepend(count, value),
              );
            },
          );
        })(),
      );
    },
  );
}

function analytics_title(title, border) {
  return $el.fragment(
    toList([
      $h.div(
        toList([$a.class$("matches-titles")]),
        toList([
          $h.div(toList([$a.class$("matches-title")]), toList([$h.text(title)])),
        ]),
      ),
      (() => {
        if (!border) {
          return $el.none();
        } else {
          return $h.div(
            toList([
              $a.style(
                toList([
                  ["height", "1px"],
                  ["max-width", "800px"],
                  ["margin-bottom", "0px"],
                  ["background", "var(--border-color)"],
                ]),
              ),
            ]),
            toList([]),
          );
        }
      })(),
    ]),
  );
}

function view_analytics(model) {
  return $el.fragment(
    toList([
      sidebar(model),
      $h.main(
        toList([$a.class$("main"), $a.style(toList([["padding", "24px 36px"]]))]),
        toList([
          analytics_title("Gloogle analytics", true),
          analytics_title("Global analytics", false),
          $h.div(
            toList([$a.class$("analytics-box-wrapper")]),
            toList([
              analytics_box("Number of searches", model.total_searches),
              analytics_box(
                "Number of signatures indexed",
                model.total_signatures,
              ),
              analytics_box("Number of packages indexed", model.total_packages),
            ]),
          ),
          analytics_title("Searches per day — Last 30 days", false),
          $h.div(
            toList([$a.class$("analytics-charts-wrapper")]),
            toList([analytics_chart(model)]),
          ),
          analytics_title("Gleam ecosystem analytics", true),
          analytics_title("Popular packages — Stars on GitHub", false),
          $h.div(
            toList([$a.class$("analytics-box-wrapper")]),
            toList([
              analytics_box(
                "Stars on gleam-lang/glang",
                (() => {
                  let _pipe = model.popular;
                  let _pipe$1 = $list.find(
                    _pipe,
                    (p) => {
                      return p.repository === "https://github.com/gleam-lang/gleam";
                    },
                  );
                  let _pipe$2 = $result.map(
                    _pipe$1,
                    (p) => {
                      let _pipe$2 = p.popularity;
                      return $option.unwrap(_pipe$2, 0);
                    },
                  );
                  return $result.unwrap(_pipe$2, 0);
                })(),
              ),
            ]),
          ),
          $h.div(
            toList([$a.class$("analytics-charts-wrapper")]),
            toList([popularity_chart(model)]),
          ),
          analytics_title(
            "Most used packages — Used as dependencies on Hex, counted by release",
            false,
          ),
          $h.div(
            toList([$a.class$("analytics-box-wrapper")]),
            toList([
              analytics_box(
                "Packages releases using gleam_stdlib",
                (() => {
                  let _pipe = model.ranked;
                  let _pipe$1 = $list.find(
                    _pipe,
                    (p) => { return p.name === "gleam_stdlib"; },
                  );
                  let _pipe$2 = $result.map(_pipe$1, (p) => { return p.rank; });
                  return $result.unwrap(_pipe$2, 0);
                })(),
              ),
              analytics_box(
                "Packages releases using gleeunit",
                (() => {
                  let _pipe = model.ranked;
                  let _pipe$1 = $list.find(
                    _pipe,
                    (p) => { return p.name === "gleeunit"; },
                  );
                  let _pipe$2 = $result.map(_pipe$1, (p) => { return p.rank; });
                  return $result.unwrap(_pipe$2, 0);
                })(),
              ),
            ]),
          ),
          $h.div(
            toList([$a.class$("analytics-charts-wrapper")]),
            toList([ranked_chart(model)]),
          ),
        ]),
      ),
    ]),
  );
}

export function body(model) {
  let $ = model.route;
  if ($ instanceof $router.Home) {
    return $h.main(
      toList([$a.class$("main")]),
      toList([view_search_input(model)]),
    );
  } else if ($ instanceof $router.Trending) {
    return $h.main(toList([$a.class$("main")]), toList([view_trending(model)]));
  } else if ($ instanceof $router.Analytics) {
    return view_analytics(model);
  } else {
    let key = $model.search_key(model.submitted_input, model);
    return $el.fragment(
      toList([
        sidebar(model),
        (() => {
          let $1 = (() => {
            let _pipe = $dict.get(model.search_results, key);
            return $result.unwrap(_pipe, new $search_result.Start());
          })();
          if ($1 instanceof $search_result.Start) {
            return empty_state(
              $images.loading,
              "Loading…",
              $frontend_strings.loading,
            );
          } else if ($1 instanceof $search_result.InternalServerError) {
            return empty_state(
              $images.internal_error,
              "Internal server error",
              $frontend_strings.internal_server_error,
            );
          } else if ($1 instanceof $search_result.SearchResults &&
          $1.exact_type_matches.hasLength(0) &&
          $1.exact_name_matches.hasLength(0) &&
          $1.name_signature_matches.hasLength(0) &&
          $1.vector_signature_searches.hasLength(0) &&
          $1.docs_searches.hasLength(0) &&
          $1.module_searches.hasLength(0)) {
            return empty_state(
              $images.shadow_lucy,
              "No match found!",
              $frontend_strings.retry_query,
            );
          } else {
            let _pipe = $dict.get(model.view_cache, key);
            let _pipe$1 = $result.map(
              _pipe,
              (_capture) => { return $lazy.lazy(_capture); },
            );
            return $result.unwrap(_pipe$1, $el.none());
          }
        })(),
      ]),
    );
  }
}
