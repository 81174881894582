/// <reference types="./decoder_extra.d.mts" />
import * as $dynamic from "../gleam_stdlib/gleam/dynamic.mjs";
import * as $option from "../gleam_stdlib/gleam/option.mjs";
import * as $result from "../gleam_stdlib/gleam/result.mjs";

export function completely_option(field) {
  return (dyn) => {
    let _pipe = $dynamic.optional_field(
      field,
      $dynamic.optional($dynamic.string),
    )(dyn);
    return $result.map(_pipe, (res) => { return $option.flatten(res); });
  };
}
