/// <reference types="./options.d.mts" />
import * as $shadow from "../../plinth/plinth/browser/shadow.mjs";
import { CustomType as $CustomType } from "../gleam.mjs";

class Node extends $CustomType {}

class Document extends $CustomType {}

class Shadow extends $CustomType {
  constructor(root) {
    super();
    this.root = root;
  }
}

class Options extends $CustomType {
  constructor(stylesheet) {
    super();
    this.stylesheet = stylesheet;
  }
}

export function node() {
  return new Options(new Node());
}

export function document() {
  return new Options(new Document());
}

export function shadow(root) {
  return new Options(new Shadow(root));
}

export function stylesheet_to_string(stylesheet) {
  if (stylesheet instanceof Node) {
    return "node";
  } else if (stylesheet instanceof Document) {
    return "document";
  } else {
    return "shadow-root";
  }
}
