/// <reference types="./search_input.d.mts" />
import * as $a from "../../../../lustre/lustre/attribute.mjs";
import * as $el from "../../../../lustre/lustre/element.mjs";
import * as $e from "../../../../lustre/lustre/event.mjs";
import * as $msg from "../../../data/msg.mjs";
import * as $s from "../../../frontend/view/search_input/styles.mjs";
import { toList } from "../../../gleam.mjs";

export function view(loading, input, small) {
  return $s.search_with_filters(
    toList([]),
    toList([
      $s.search_input_wrapper(
        loading,
        toList([
          $s.search_input(
            loading,
            small,
            toList([
              $s.search_input_content(
                toList([
                  $a.id("search-input"),
                  $a.placeholder("Search for a function, or a type"),
                  $e.on_input((var0) => { return new $msg.UpdateInput(var0); }),
                  $a.value(input),
                  $a.attribute("autocorrect", "off"),
                  $a.attribute("autocapitalize", "none"),
                  $a.attribute("autofocus", "autofocus"),
                ]),
              ),
              $s.shortcut_hint(toList([]), toList([$el.text("s")])),
            ]),
          ),
        ]),
      ),
    ]),
  );
}
