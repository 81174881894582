/// <reference types="./kind.d.mts" />
import * as $dynamic from "../../gleam_stdlib/gleam/dynamic.mjs";
import * as $result from "../../gleam_stdlib/gleam/result.mjs";
import { Ok, Error, toList, CustomType as $CustomType } from "../gleam.mjs";

export class Function extends $CustomType {}

export class TypeDefinition extends $CustomType {}

export class TypeAlias extends $CustomType {}

export class Constant extends $CustomType {}

export function decode_kind(dyn) {
  return $result.try$(
    $dynamic.string(dyn),
    (str) => {
      if (str === "function") {
        return new Ok(new Function());
      } else if (str === "type_definition") {
        return new Ok(new TypeDefinition());
      } else if (str === "type_alias") {
        return new Ok(new TypeAlias());
      } else if (str === "constant") {
        return new Ok(new Constant());
      } else {
        return new Error(toList([new $dynamic.DecodeError("", "", toList([]))]));
      }
    },
  );
}

export function display_kind(kind) {
  if (kind instanceof Function) {
    return "Function";
  } else if (kind instanceof TypeDefinition) {
    return "Type";
  } else if (kind instanceof TypeAlias) {
    return "Type Alias";
  } else {
    return "Constant";
  }
}
